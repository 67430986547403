/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import defaultAvatar from './../../../../assets/image/blank.png';
import { ROUTES } from '../../../Routes.models';

export function ProfileCard() {
  const intl = useIntl();
  const user = useSelector(state => state.auth.user, shallowEqual);
  const avatar = useSelector(state => state.auth.user.avatar, shallowEqual);

  return (
    <>
      {user && (
        <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
          <div className="card card-custom card-stretch">
            <div className="card-body pt-8">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div
                    className="symbol-label"
                    style={{ backgroundImage: `url(${avatar ? avatar : defaultAvatar})` }}
                  ></div>
                  <i className="symbol-badge bg-success"></i>
                </div>
                <div>
                  <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                    {user.name} {user.surname}
                  </a>
                </div>
              </div>
              <div className="py-9">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Email:</span>
                  <span className="text-muted text-hover-primary">{user?.email}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">{intl.formatMessage({ id: 'CONTACT_PHONE' })}:</span>
                  <span className="text-muted">{user?.phone}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="font-weight-bold mr-2">{intl.formatMessage({ id: 'ADDRESS' })}:</span>
                  <span className="text-muted">{user?.place}</span>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink to={ROUTES.USER_PROFILE_INFO} className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}></SVG>{' '}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">{intl.formatMessage({ id: 'PERSONAL_INFORMATION' })}</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink to={ROUTES.USER_PROFILE_PASS} className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Shield-user.svg')}></SVG>{' '}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">{intl.formatMessage({ id: 'CHANGE_PASS' })}</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink to={ROUTES.USER_PROFILE_BUILDER} className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')}></SVG>{' '}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Настройки</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
