import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';

import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';

import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  getHandlerTableChange,
  toAbsoluteUrl,
} from '../../../../../../../_metronic/_helpers';
import { Pagination } from '../../../../../../../_metronic/_partials/controls';
import { useDispatchingUIContext } from '../../DispatchingUIContext';
import * as uiHelpers from '../../DispatchingUIHelpers';
import * as actions from '../../../../redux/dispatching/dispatchingActions';
import { ROUTES } from '../../../../../../Routes.models';
import { Link } from 'react-router-dom';
import * as columnFormatters from './column-formatters';
import { Dropdown, SplitButton, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export const DispatchingTable = ({ entities, loading, totalCount }) => {
  const dispatchingUIContext = useDispatchingUIContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatchingUIProps = useMemo(() => {
    return {
      queryParams: dispatchingUIContext.queryParams,
      setQueryParams: dispatchingUIContext.setQueryParams,
    };
  }, [dispatchingUIContext.queryParams, dispatchingUIContext.setQueryParams]);

  useEffect(() => {
    dispatch(actions.getDispObjects(dispatchingUIProps.queryParams));
  }, [dispatch, dispatchingUIProps.queryParams]);

  const actionsField = (_cellContent, row, _rowIndex) => {
    return (
      <Link
        to={`${ROUTES.TOOLS_DISPATCHING}/${row.id}`}
        title="Открыть объект"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Sign-in.svg')} />
        </span>
      </Link>
    );
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      sort: true,
    },
    {
      dataField: 'title',
      text: 'Название',
      sort: true,
    },
    {
      dataField: 'oid_name',
      text: 'Организация',
      sort: true,
    },
    {
      dataField: 'address',
      text: 'Адрес',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Статус',
      formatter: columnFormatters.StatusColumnFormatter,
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Действия',
      formatter: (_cell, row, _rowIndex) => {
        return (
          <Button variant="secondary" size="sm" onClick={() => history.push(`/tools/dispatching/${row.id}`)}>
            Открыть
          </Button>
        );
      },
      classes: 'text-center nowrap',
      headerClasses: 'text-center nowrap',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Показывать строки с {from} по {to} из {size}
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: dispatchingUIProps.queryParams.pageSize,
    page: dispatchingUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination isLoading={loading} paginationProps={paginationProps}>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              remote
              keyField="id"
              data={entities}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(dispatchingUIProps.setQueryParams)}
              {...paginationTableProps}
            >
              <PleaseWaitMessage entities={entities} />
              <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};
