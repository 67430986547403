/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from '../../../_helpers';

export function TilesWidget3({ className, widgetHeight = '175px', title = 'Properties', href }) {
  const backgroundImageUrl = toAbsoluteUrl('/media/bg/bg-9.jpg');
  const history = useHistory();

  const redirect = useCallback(() => {
    history.push(href);
  }, [history, href]);

  return (
    <>
      {/* begin::Tiles Widget 3 */}
      <div
        className={`card card-custom bgi-no-repeat bgi-no-repeat bgi-size-cover ${className}`}
        style={{
          height: widgetHeight,
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex flex-column">
          {/* begin::Title */}
          <a onClick={() => redirect()} className="text-dark-75 text-hover-primary font-weight-bolder font-size-h3">
            {title}
          </a>
          {/* end::Title */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 3 */}
    </>
  );
}
