export const STATUS = {
  WAIT_EXECUTOR: 'В очереди',
  WAIT_EXEC_APPLICATION: 'Выполняется',
  COMPLETE: 'Готов'
};

export const CALCULATIONS_STATUS = [
  '',
  STATUS.WAIT_EXECUTOR,
  STATUS.WAIT_EXEC_APPLICATION,
  STATUS.COMPLETE
];

export const TYPES = {
  CALC_BTP: 'Расчет БТП',
  CALC_BOILER: 'Расчет теплообменника',
  CALC_UU: 'Расчет узла учета'
};

export const CALCULATIONS_TYPES = [
  '',
  '',
  TYPES.CALC_BTP,
  TYPES.CALC_BOILER,
  TYPES.CALC_UU
];

export const NotificationStatusCssClasses = ["", "primary", "warning", "success"];
export const NotificationStatusTitles = ["Suspended", "Active", "Pending", ""];
export const NotificationTypeCssClasses = ["success", "primary", ""];
export const NotificationTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    module: "",
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};