import React from 'react';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';

export const NewsWidget = ({ news }) => {
  return (
    <>
      <Card className="card-stretch">
        <CardHeader>
          <h3 className="card-title align-items-start flex-column text-dark my-5">
            <span className="font-weight-bolder text-dark">Последние новости</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">Новости компании и отрасли</span>
          </h3>
        </CardHeader>
        <CardBody>
          {news.map(entiti => (
            <div key={entiti.id} className="d-flex align-items-center mb-10">
              <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div className="symbol-label" style={{ backgroundImage: `url(${encodeURI(entiti.preview)})` }}></div>
              </div>
              <div className="d-flex flex-column flex-grow-1">
                <a
                  href={`${entiti.url}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-dark font-weight-bolder font-size-lg text-hover-primary mb-1"
                >
                  {entiti.title}
                </a>
                <span className="text-dark-50 font-weight-normal font-size-sm">{entiti.description}</span>
              </div>
            </div>
          ))}
        </CardBody>
      </Card>
    </>
  );
};
