import React from 'react';

import { ScannerUIProvider,  } from './ScannerUIContext';
import { ScannerCard } from './ScannerCard';

export const ScannerPage = () => {
  return (
    <>
      <ScannerUIProvider>
        <ScannerCard />
      </ScannerUIProvider>
    </>
  );
};