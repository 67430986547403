import { createSlice } from '@reduxjs/toolkit';

const initialOrderState = {
  orderLoading: false,
  orderNameLoading: false,
  listLoading: false,
  commentLoading: false,
  approving: false,
  listDeleting: false,
  countFetching: false,
  docLoading: false,
  excelLoading: false,

  entities: null,
  tableEntities: null,
  tableTotal: null,
  lastError: null,
  someResultText: '',
  statusClient: null,
  orderDocuments: [],
  error: null,
};

export const callTypes = {
  data: 'data',
  comment: 'comment',
  list: 'list',
  deleteProduct: 'deleteProduct',
  count: 'count',
  approve: 'approve',
  getDoc: 'getDoc',
  sendExcel: 'sendExcel',
  orderName: 'orderName',
};

export const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.data) {
        state.orderLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.comment) {
        state.commentLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.deleteProduct) {
        state.listDeleting = false;
        return;
      }

      if (action.payload.callType === callTypes.count) {
        state.countFetching = false;
        return;
      }

      if (action.payload.callType === callTypes.approve) {
        state.approving = false;
        return;
      }

      if (action.payload.callType === callTypes.getDoc) {
        state.docLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.sendExcel) {
        state.excelLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.orderName) {
        state.orderNameLoading = false;
        return;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.data) {
        state.orderLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.comment) {
        state.commentLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.deleteProduct) {
        state.listDeleting = true;
        return;
      }

      if (action.payload.callType === callTypes.count) {
        state.countFetching = true;
        return;
      }

      if (action.payload.callType === callTypes.approve) {
        state.approving = true;
        return;
      }

      if (action.payload.callType === callTypes.getDoc) {
        state.docLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.sendExcel) {
        state.excelLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.orderName) {
        state.orderNameLoading = true;
        return;
      }
    },

    orderFetched: (state, action) => {
      const { entities } = action.payload;
      state.orderLoading = false;
      state.error = null;
      state.entities = entities;
      state.statusClient = entities.status_client;
    },

    commentFetched: (state, action) => {
      const { type } = action.payload;
      state.commentLoading = false;
      state.error = null;
      state.someResultText = type;
    },

    orderNameFetched: (state, action) => {
      const { type } = action.payload;
      state.orderNameLoading = false;
      state.error = null;
      state.someResultText = type;
    },

    clearSomeResultText: state => {
      state.someResultText = initialOrderState.someResultText;
    },

    orderListFetched: (state, action) => {
      const { data, total } = action.payload;
      state.tableEntities = data;
      state.tableTotal = total;
      state.listLoading = false;
      state.error = null;
    },

    clearOrderState: state => {
      Object.keys(state).map(stateItem => (state[stateItem] = initialOrderState[stateItem]));
    },

    productDeleted: state => {
      state.listDeleting = false;
    },

    incrementedProductCount: (state, action) => {
      state.countFetching = false;
      state.someResultText = action.payload.type;
    },

    decrementedProductCount: (state, action) => {
      state.countFetching = false;
      state.someResultText = action.payload.type;
    },

    approved: (state, action) => {
      state.approving = false;
      state.someResultText = action.payload.type;
    },

    docFetched: (state, action) => {
      state.docLoading = false;
      state.orderDocuments = action.payload;
    },

    excelSended: (state, action) => {
      state.excelLoading = false;
      state.someResultText = action.payload.text;
    },
  },
});
