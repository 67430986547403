import { createSlice } from '@reduxjs/toolkit';

const initialChatsState = {
  orderChatLoading: false,
  entities: null,
};

export const callTypes = {
  orderChatLoad: 'orderChatLoad',
  orderChatAdd: 'orderChatAdd',
};

export const chatsSlice = createSlice({
  name: 'chats',
  initialState: initialChatsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.orderChatLoad) {
        state.orderChatLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.orderChatLoad) {
        state.orderChatLoading = true;
      }
    },
    orderChatLoadFetched: (state, action) => {
      const { data } = action.payload;
      state.orderChatLoading = false;
      state.error = null;
      state.entities = data;
    },
  },
});
