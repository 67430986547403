import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen } from '../_metronic/layout';
import { HomePage } from './modules/Home/HomePage';
import { NotificationsPage } from './modules/Notifications/NotificationsPage';
import { UserInfoPage } from './modules/UserInfo/UserInfoPage';
import { ROUTES } from './Routes.models';
import { ReportsPage } from './modules/Support/ReportsPage';
import { PromotionsPage } from './modules/Promotions/PromotionsPage';
import { PromotionPage } from './modules/Promotion/PromotionPage';
import { ScannerPage } from './modules/Tools/pages/scanner/ScannerPage';
import { DispatchingPage } from './modules/Tools/pages/dispatchingObjects/DispatchingPage';
import { DispatchingObjPage } from './modules/Tools/pages/dispatchingObject/DispatchingObjPage';

const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to={ROUTES.HOME} />
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.PROMOTIONS} component={PromotionsPage} />
        <Route exact path={ROUTES.PROMOTION}>
          {({ match }) => <PromotionPage id={match && match.params.id} match={match} />}
        </Route>
        <Route path={ROUTES.NOTIFI} component={NotificationsPage} />
        <Route path={ROUTES.E_COMMERCE} component={ECommercePage} />
        <Route path={ROUTES.USER} component={UserInfoPage} />
        <Route path={ROUTES.SUPPORT_REPORTS} component={ReportsPage} />
        <Route path={ROUTES.TOOLS_SCANNER} component={ScannerPage} />
        <Route exact path={ROUTES.TOOLS_DISPATCHING} component={DispatchingPage} />
        <Route path={ROUTES.TOOLS_DISPATCHING_OBJ}>
          {({ match }) => <DispatchingObjPage id={match && match.params.id} match={match} />}
        </Route>
        <Redirect to={ROUTES.ERROR} />
      </Switch>
    </Suspense>
  );
}
