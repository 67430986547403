import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import YandexMaps from '../../../../../services/yandexMapsServices';

let yandexMaps = null;

export const MyObjectsMapContent = ({ onHide, entities }) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    yandexMaps = new YandexMaps({ ymaps });
    yandexMaps.ymaps.ready(() => {
      yandexMaps.init();
      if (entities.length > 1) {
        yandexMaps.renderAllObjects(entities);
        return;
      };

      if (entities.coords) {
        yandexMaps.renderOneObject(entities);
      };

    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{entities && entities.length > 1 ? 'Карта объектов' : 'Карта объекта'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        <div className="form-group row">
          <div className="col-12">
            <div id="map"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
          Закрыть
        </button>
      </Modal.Footer>
    </>
  );
};