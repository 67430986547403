/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { ROUTES } from './../../../../../app/Routes.models';

export function AsideMenuList({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();

  const { authState } = useSelector(state => ({ authState: state.auth }));
  const isScadaEnabled = authState.user.module.scada.enable;

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url, hasSubmenu) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive(ROUTES.HOME, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.HOME}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Home.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.HOME' })}</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.NOTIFI, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.NOTIFI}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Mail-notification.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.NOTIFY' })}</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.PROMOTIONS, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.PROMOTIONS}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Sale1.svg')} />
            </span>
            <span className="menu-text">Акции</span>
          </NavLink>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.ORDERS' })}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.ORDERS, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.ORDERS}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart1.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'ORDERS' })}</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.ORDERS_ARCHIVE, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.ORDERS_ARCHIVE}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Box.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'ORDERS_ARCHIVE' })}</span>
          </NavLink>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.DESIGN' })}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.CALCULATIONS, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.CALCULATIONS}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Calculator.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'CALCULATIONS' })}</span>
          </NavLink>
        </li>

        <li
          className={`menu-item  menu-item-submenu ${getMenuItemActive(ROUTES.NEW_CALC, true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link" to={ROUTES.NEW_CALC}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')} />
            </span>
            <span className="menu-text">Новый расчет</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item hoverBlue ${getMenuItemActive(ROUTES.NEW_BTP, true)}`}>
                <NavLink className="menu-link" to={ROUTES.NEW_BTP}>
                  <span className="menu-text">
                    Расчет БТП &nbsp;<b>β</b>
                  </span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(ROUTES.NEW_TO, true)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
                data-toggle="dropdown"
              >
                <NavLink className="menu-link" to={ROUTES.NEW_TO}>
                  <span className="menu-text">Расчет теплообменника</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_TO1)}`}>
                      <NavLink className="menu-link" to={ROUTES.NEW_TO1}>
                        <span className="menu-text">Стандартный расчет</span>
                      </NavLink>
                    </li>
                    <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_TO2)}`}>
                      <NavLink className="menu-link" to={ROUTES.NEW_TO2}>
                        <span className="menu-text">Двухступенчатая схема</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
              <li className={`menu-item hoverBlue ${getMenuItemActive(ROUTES.NEW_PUMP)}`}>
                <NavLink className="menu-link" to={ROUTES.NEW_PUMP}>
                  <span className="menu-text">
                    Расчет насосов &nbsp;<b>β</b>
                  </span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_PUMP_STATION)}`}>
                <NavLink className="menu-link" to={ROUTES.NEW_PUMP_STATION}>
                  <span className="menu-text">
                    Расчет насосной станции &nbsp;<b>β</b>
                  </span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_FLOW_METER, false)}`} aria-haspopup="true">
                <NavLink className="menu-link" to={ROUTES.NEW_FLOW_METER}>
                  <span className="menu-text">
                    Расчет узла учета &nbsp;<b>β</b>
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">Инструменты</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.TOOLS_SCANNER, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.TOOLS_SCANNER}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Barcode-read.svg')} />
            </span>
            <span className="menu-text">
              Сканер документов &nbsp;<b>β</b>
            </span>
          </NavLink>
        </li>
        {isScadaEnabled && (
          <li className={`menu-item ${getMenuItemActive(ROUTES.TOOLS_DISPATCHING, false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to={ROUTES.TOOLS_DISPATCHING}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Chart-line1.svg')} />
              </span>
              <span className="menu-text">Диспетчеризация</span>
            </NavLink>
          </li>
        )}

        <li className="menu-section">
          <h4 className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.PERSONAL_DATA' })}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.USER_PROFILE, true)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.USER_PROFILE}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Add-user.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'USERPROFILE' })}</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.USER_ORGANISATIONS, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.USER_ORGANISATIONS}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Clothes/Shirt.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'ORGANIZATIONS' })}</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.USER_OBJECTS, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.USER_OBJECTS}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Building.svg')} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: 'OBJECTS' })}</span>
          </NavLink>
        </li>

        <li className="menu-section">
          <h4 className="menu-text">Поддержка</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive(ROUTES.SUPPORT, false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to={ROUTES.SUPPORT_REPORTS}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
            <span className="menu-text">Обращения</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
