import store from '../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD, REACT_APP_REQ_PROD_API, REACT_APP_REQ_DEV_API} = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

const DISPATCHING_GET_OBJECTS = `${ENDPOINT}api/app/scada.get?`;
const DISPATCHING_GET_OBJECT = `${ENDPOINT_API}v1/scada`;
const DISPATCHING_GET_OBJECT_VAR = `${ENDPOINT_API}v1/scada/tags`;

export const getDispObjects = async queryParams => {
  const token = store.getState().auth.authToken;
  const filter = JSON.stringify(queryParams.filter);

  return await fetch(
    `${DISPATCHING_GET_OBJECTS}search=${queryParams.searchText}&filter=${filter}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}&token=${token}`,
  ).then(result => result.json());
};

export const getDispObject = async queryParams => {
  const token = store.getState().auth.authToken;

  return await fetch(`${DISPATCHING_GET_OBJECT}/${queryParams.id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  }).then(result => result.json());
};

export const getDispObjectVar = async queryParams => {
  const token = store.getState().auth.authToken;

  return await fetch(`${DISPATCHING_GET_OBJECT_VAR}/${queryParams.id}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  }).then(result => result.json());
};



