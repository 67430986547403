// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import * as actions from "../_redux/notificationsActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../NotificationsUIHelpers";
import * as columnFormatters from "./../column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useNotificationsUIContext } from "../NotificationsUIContext";

export function NotificationsTable() {
  const intl = useIntl();
  const notificationsUIContext = useNotificationsUIContext();
  const notificationsUIProps = useMemo(() => {
    return {
      queryParams: notificationsUIContext.queryParams,
      setQueryParams: notificationsUIContext.setQueryParams,
    };
  }, [notificationsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.notifications }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchNotifications(notificationsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "icon",
      text: "",
      sort: false,
      formatter: cell => <img src={cell} alt="" width="50" height="50"/>,
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "title",
      text: intl.formatMessage({ id: "TABLE.TITLE" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        width: "200px",
      },
    },
    {
      dataField: "date",
      text: intl.formatMessage({ id: "TABLE.DATE" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "120px",
      },
    },
    {
      dataField: "text",
      text: "Описание",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "200px",
      },
    },
    {
      dataField: "module",
      text: "Модуль",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        minWidth: "120px",
      },
    },
    {
      dataField: "url",
      text: intl.formatMessage({ id: "TABLE.ACTION" }),
      formatter: columnFormatters.ActionsColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Показывать строки с {from} по {to} из {size}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: notificationsUIProps.queryParams.pageSize,
    page: notificationsUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  notificationsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
