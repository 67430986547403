import React from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectEditForm } from './ObjectEditForm';

export const ObjectsEditDialog = ({ show, onHide }) => {
  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="objectsEditDialog">Новый объект</Modal.Title>
      </Modal.Header>
      <ObjectEditForm onHide={onHide} />
    </Modal>
  )
};