import * as requestFromServer from './reportsCrud';
import { reportsSlice, callTypes } from './reportsSlice';

const { actions } = reportsSlice;

export const fetchShowReports = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.report }));
  return requestFromServer
    .getReport(queryParams)
    .then(response => {
      const entities = response.data;
      dispatch(actions.reportFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't find reports!";
      dispatch(actions.catchError({ error, callType: callTypes.report }));
    });
};

export const fetchShowMsgsReports = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.msgs }));
  return requestFromServer
    .showSupMsgsReports(queryParams)
    .then(response => {
      dispatch(actions.msgsFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't find reports messages!";
      dispatch(actions.catchError({ error, callType: callTypes.msgs }));
    });
};

export const fetchCreateMsgsReport = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.createMsgs }));
  return requestFromServer
    .createMsgsReports(queryParams)
    .then(response => {
      dispatch(actions.msgCreateFetched());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create reports messages!";
      dispatch(actions.catchError({ error, callType: callTypes.createMsgs }));
    });
};

export const fetchCreateReport = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.createReport }));
  return requestFromServer
    .createReport(queryParams)
    .then(response => {
      dispatch(actions.createReportFetched());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create report!";
      dispatch(actions.catchError({ error, callType: callTypes.createReport }));
    });
};

export const fetchClearReportMsgs = () => dispatch => {
  dispatch(actions.clearReportMsgs());
};

export const fetchCreateFileReport = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.createFile }));
  return requestFromServer
    .createFileReports(queryParams)
    .then(response => {
      dispatch(actions.fileCreateFetched());
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create report file!";
      dispatch(actions.catchError({ error, callType: callTypes.createFile }));
    });
};
