import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { useMyObjectsUIContext } from '../MyObjectsUIContext';

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };

  newQueryParams.searchText = searchText;
  return newQueryParams;
};

export function MyObjectsFilter() {
  const intl = useIntl();
  const myObjectsUIContext = useMyObjectsUIContext();
  const myObjectsUIProps = useMemo(() => {
    return {
      queryParams: myObjectsUIContext.queryParams,
      setQueryParams: myObjectsUIContext.setQueryParams,
    };
  }, [myObjectsUIContext]);

  const applyFilter = values => {
    const newQueryParams = prepareFilter(myObjectsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, myObjectsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      myObjectsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: '',
        }}
        onSubmit={values => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="Поиск"
                  name="searchText"
                  autoComplete="off"
                  onChange={e => {
                    setFieldValue('searchText', e.target.value);
                    (e.target.value.length >= 3 || e.target.value.length === 0) && handleSubmit();
                  }}
                  value={values.searchText}
                />
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: 'TABLE.FILTER.SEARCH' })}</b>{' '}
                  {intl.formatMessage({ id: 'TABLE.FILTER.IN_ALL_FIELDS' })}
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
