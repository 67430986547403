/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from '../../../_helpers';
import backgroundImageUrl from './../../../../assets/image/dashboardTO.jpg';

export function TilesWidget14({
  className,
  row1 = 'Create Reports',
  row2 = 'With App',
  titleBtn = 'Create Report',
  href,
}) {
  const history = useHistory();

  const redirect = useCallback(() => {
    history.push(href);
  }, [history, href]);

  return (
    <>
      <div
        onClick={() => redirect()}
        className={`card card-custom bgi-no-repeat bgi-size-cover cursor-pointer ${className}`}
        style={{
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
      >
        <div className="card-body d-flex flex-column align-items-start justify-content-start">
          <div className="p-1 flex-grow-1">
            <h3 className="text-white font-weight-bolder line-height-lg mb-5">
              {row1}
              <br />
              {row2}
            </h3>
          </div>
          <a onClick={() => redirect()} className="btn btn-link btn-link-light font-weight-bold">
            {titleBtn}
            <span className="svg-icon-lg svg-icon-warning">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')} />
            </span>
          </a>
        </div>
      </div>
    </>
  );
}
