/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, Switch, Redirect } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute } from '../../../../_metronic/layout';
import { RegistrationV2 } from './RegistrationV2';
import { ForgotPassword } from './ForgotPassword';
import Login from './Login';
import { CheckCode } from './CheckCode';
import { CardSpinner } from '../../../widgets/cardSpinner/CardSpinner';
import logoImg from './../../../../assets/image/logo-letter-1.png';
import { ROUTES } from '../../../Routes.models';

import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export function AuthPage() {
  const intl = useIntl();
  const { currentState } = useSelector(state => ({ currentState: state.auth }));
  const { userLoading, codeGetting } = currentState;

  return (
    <>
      {(userLoading || codeGetting) && <CardSpinner />}
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
            }}
          >
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img alt="Logo" className="max-h-70px" src={logoImg} />
              </Link>
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">{intl.formatMessage({ id: 'WELCOME' })}</h3>
                <p className="font-weight-lighter text-white opacity-80">
                  {intl.formatMessage({ id: 'WELCOME_TEXT' })}
                </p>
              </div>
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  Copyright &copy; 2012–2022 - Все права защищены!
                </div>
                <div className="d-flex">
                  <a
                    href={`${ENDPOINT}feedback`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white ml-10"
                  >
                    {intl.formatMessage({ id: 'AUTH.GENERAL.CONTACT' })}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path={ROUTES.AUTH_REG} component={RegistrationV2} />
                <ContentRoute path={ROUTES.AUTH_CHECK} component={CheckCode} />
                <ContentRoute path={ROUTES.AUTH_LOGIN} component={Login} />
                <ContentRoute path={ROUTES.AUTH_FORGOT} component={ForgotPassword} />
                <Redirect from={ROUTES.AUTH} exact={true} to={ROUTES.AUTH_REG} />
                <Redirect to={ROUTES.AUTH_REG} />
              </Switch>
            </div>
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                Copyright &copy; 2012–2022 - Все права защищены!
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <a
                  href={`${ENDPOINT}feedback`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  {intl.formatMessage({ id: 'AUTH.GENERAL.CONTACT' })}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
