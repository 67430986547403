import React from "react";
import { MyOrganisationsCard } from "./MyOrganisationsCard";
import { MyOrganisationsUIProvider } from "./MyOrganisationsUIContext";

export const MyOrganisationsPage = () => {
  return (
    <MyOrganisationsUIProvider>
      <MyOrganisationsCard />
    </MyOrganisationsUIProvider>
  );
};