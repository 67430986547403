import { createSlice } from '@reduxjs/toolkit';

const initialPromotionsState = {
  promotionsLoading: false,
  promotionsEntities: [],
};
export const callTypes = {
  getProm: 'getProm',
};

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: initialPromotionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.getProm) {
        state.promotionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.getProm) {
        state.promotionsLoading = true;
      }
    },

    promotionsFetched: (state, action) => {
      const { entities } = action.payload.data;
      state.promotionsEntities = entities;
      state.promotionsLoading = false;
      state.error = null;
    },
  },
});
