import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export const DispatchingObjProject = ({ id }) => {
  return (
    <iframe
      src={`${ENDPOINT}scada/project/${id}`}
      name="btpIFrame"
      frameBorder="0"
      id="btpIFrame"
      width="100%"
      height="900"
    />
  );
};
