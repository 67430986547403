import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as action from './../_redux/authActions';
import { useEffect } from 'react';
import { ROUTES } from '../../../Routes.models';

const initialValues = {
  phoneEmail: '',
  password: '',
};

const Login = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [passInputType, setPassInputType] = useState('password');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(action.logout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoginSchema = Yup.object().shape({
    phoneEmail: Yup.string()
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    password: Yup.string()
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting, setFieldValue, isSubmitting, errors, setErrors, resetForm }) => {
      setSubmitting(true);
      enableLoading();
      dispatch(action.fetchToken(values))
        .then(() => {
          dispatch(action.fetchUser());
        })
        .catch(e => {
          disableLoading();
          setSubmitting(false);
          setFieldValue('password', '');
          setStatus(e.message);
          dispatch(action.logout());
        });
    },
  });

  const checkPasswordVisibility = useCallback(e => {
    if (e.target.checked) {
      setPassInputType('text');
      return;
    }

    setPassInputType('password');
  }, []);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-left">
        <h3 className="font-size-h1">Войти с паролем</h3>
        <p className="text-muted font-weight-bold font-size-h6">Введите телефон или E-mail и пароль</p>
      </div>
      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}
        <div className="form-group fv-plugins-icon-container">
          <label className="col-form-label text-center font-weight-bold">
            <p className="font-weight-bold font-size-h6">Телефон или e-mail</p>
          </label>
          <input
            placeholder="Phone or e-mail"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
            name="phoneEmail"
            {...formik.getFieldProps('phoneEmail')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label className="col-form-label text-center font-weight-bold">
            <p className="font-weight-bold font-size-h6">Пароль</p>
          </label>
          <input
            placeholder="Password"
            type={passInputType}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group row">
          <div className="col-lg-9 col-xl-12 d-flex justify-content-between align-items-center">
            <label className="col-form-label text-alert ml-2 no-select">
              <input className="mr-3" type="checkbox" onChange={e => checkPasswordVisibility(e)} />
              Показать пароль
            </label>
            <Link to={ROUTES.AUTH_FORGOT} className="text-primary text-hover-primary">
              Забыли пароль?
            </Link>
          </div>
        </div>
        <div className="form-group d-flex flex-wrap align-items-center flex-center">
          <Link to={ROUTES.AUTH_REG} className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
            Назад
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>{intl.formatMessage({ id: 'SIGN_IN' })}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
