import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";

import { PromoWidgetRow } from "./PromoWidgetRow";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";

export const PromoWidget = ({ className, title, entities }) => {
  const promoLength = 4;

  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
  };

  return (
    <>
      <Card className={className}>
        <CardHeader >
          <h3 className="card-title align-items-start flex-column text-dark my-5">
            <span className="font-weight-bolder text-dark">{title}</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">Закажите товары со скидкой</span>
          </h3>
        </CardHeader>
        <CardBody>
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            className="navi navi-hover scroll my-4"
            style={{ maxHeight: "300px", position: "relative" }}
          >
            {entities.length ? (
              entities.map((entity, entityIndex) => entityIndex > promoLength ? <div key={entity.id}></div> : <PromoWidgetRow key={entity.id} entity={entity}/>)
            ) : (
              <div className="d-flex flex-wrap align-items-center mb-6">
                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3 ml-3">
                  <span className="text-dark-75 font-weight-bolder font-size-lg">
                    В данный момент акций нет
                  </span>
                </div>
              </div>
            )}
          </PerfectScrollbar>
        </CardBody>
      </Card>
    </>
  );
}