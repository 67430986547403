import React, { useCallback, useState } from "react";
import { Field, Formik } from "formik";
import { useIntl } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { recovery } from "../_redux/authCrud";
import { ROUTES } from "../../../Routes.models";

export const ForgotPassword = () => {
  const intl = useIntl();
  const [isRequested, setIsRequested] = useState(false);
  const initialValues = {
    email: "",
  };

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const recoveryPass = useCallback((values, setSubmitting, setStatus, resetForm) => {
    recovery(values)
      .then(() => setIsRequested(true))
      .catch(() => {
        setIsRequested(false);
        setSubmitting(false);
        setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.NOT_FOUND" }, { name: values.email }));
      });
  }, [intl]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordSchema}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        recoveryPass(values, setSubmitting, resetForm);
      }}
    >
      {({ errors, status, touched, isSubmitting, handleSubmit }) => (
        <>
          {isRequested && <Redirect to={ROUTES.AUTH} />}
          {!isRequested && (
            <div className="login-form login-forgot" style={{ display: "block" }}>
              <div className="text-left">
                <h3 className="font-size-h1">Восстановление пароля</h3>
                <p className="text-muted font-weight-bold font-size-h6">
                  Укажите E-mail или телефон
                </p>
              </div>
              <form
                onSubmit={handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                {status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {status}
                    </div>
                  </div>
                )}
                <div className="form-group fv-plugins-icon-container">
                  <Field
                    type="email"
                    name="email"
                    className={
                      `form-control form-control-solid h-auto py-5 px-6
                      ${((touched.email && errors.email) && "is-invalid") || ((touched.email && !errors.email) && "is-valid")}`
                    }
                  />
                  {touched.email && errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.email}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={isSubmitting}
                  >
                    Отправить
                  </button>
                  <Link to={ROUTES.AUTH}>
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Отмена
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </Formik>
  );
};