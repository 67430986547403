import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const PROMOTIONS = `${ENDPOINT}api/app/promotions.get?&sortField=id&sortOrder=desc`;

export const getPromotions = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return await fetch(`${PROMOTIONS}&token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());
};
