/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Snackbar } from '@material-ui/core';

import { ModalProgressBar } from '../../../../../_metronic/_partials/controls';
import { changePass, checkPassword } from '../../../Auth/_redux/authCrud';
import { ROUTES } from '../../../../Routes.models';

export const ChangePassword = () => {
  const intl = useIntl();
  const [loading, setloading] = useState(false);
  const [isShowAlertSnackbars, setIsShowAlertSnackbars] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { user } = useSelector(state => state.auth.user, shallowEqual);

  useEffect(() => {}, [user]);

  const handleSnackbarClose = useCallback(() => {
    setIsShowAlertSnackbars(false);
  }, []);

  const saveUser = (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    setloading(true);
    changePass(values)
      .then(() => {
        setAlertMessage(intl.formatMessage({ id: 'PASS_CHANGED_SUCCES' }));
      })
      .catch(e => {
        setAlertMessage(intl.formatMessage({ id: 'ERROR' }));
      })
      .finally(() => {
        setTimeout(() => {
          setSubmitting(false);
          setloading(false);
          setIsShowAlertSnackbars(true);
          resetForm();
        }, 500);
      });
  };

  const initialValues = {
    currentPassword: '',
    password: '',
    cPassword: '',
  };

  const Schema = Yup.object().shape({
    password: Yup.string()
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, intl.formatMessage({ id: 'PASS_SIMPLE' }))
      .required(intl.formatMessage({ id: 'REQUIRED_PASS' })),
    cPassword: Yup.string()
      .required(intl.formatMessage({ id: 'REQUIRED_PASS_CONFIRM' }))
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], intl.formatMessage({ id: 'PASS_NOT_MATCH' })),
      }),
  });

  const validateСurrentPassword = useCallback(
    value => {
      if (!value) {
        return intl.formatMessage({ id: 'REQUIRED_PASS' });
      }

      return checkPassword(value).then(res => {
        if (!res) {
          return intl.formatMessage({ id: 'BAD_PASS' });
        }
      });
    },
    [intl],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      validateOnChange={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        saveUser(values, setSubmitting, resetForm);
      }}
    >
      {({ errors, touched, isSubmitting, isValid, handleSubmit }) => (
        <form className="card card-custom" onSubmit={handleSubmit}>
          {loading && <ModalProgressBar />}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isShowAlertSnackbars}
            onClose={() => handleSnackbarClose()}
            ContentProps={{ 'aria-describedby': 'message-id' }}
            message={
              <span className="alertSnackbar" id="message-id">
                {alertMessage}
              </span>
            }
          />
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">{intl.formatMessage({ id: 'CHANGE_PASS' })}</h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                {intl.formatMessage({ id: 'CHANGE_YOUR_ACC_PASS' })}
              </span>
            </div>
            <div className="card-toolbar">
              <button type="submit" className="btn btn-success mr-2" disabled={isSubmitting || (touched && !isValid)}>
                {intl.formatMessage({ id: 'SAVE_CHANGES' })}
              </button>
              <Link to={ROUTES.USER_PROFILE} className="btn btn-secondary">
                {intl.formatMessage({ id: 'CANCEL' })}
              </Link>
            </div>
          </div>
          <div className="form">
            <div className="card-body">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  {intl.formatMessage({ id: 'CURRENT_PASS' })}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <Field
                    type="password"
                    name="currentPassword"
                    placeholder={intl.formatMessage({ id: 'CURRENT_PASS' })}
                    className={`form-control form-control-lg form-control-solid mb-2
                    ${(touched.currentPassword && errors.currentPassword && 'is-invalid') ||
                      (touched.currentPassword && !errors.currentPassword && 'is-valid')}`}
                    validate={validateСurrentPassword}
                  />
                  {touched.currentPassword && errors.currentPassword ? (
                    <div className="invalid-feedback">{errors.currentPassword}</div>
                  ) : null}
                  <a href="#" className="text-sm font-weight-bold">
                    {intl.formatMessage({ id: 'FORGOT_PASS' })}
                  </a>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  {intl.formatMessage({ id: 'NEW_PASS' })}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <Field
                    type="password"
                    name="password"
                    placeholder={intl.formatMessage({ id: 'NEW_PASS' })}
                    className={`form-control form-control-lg form-control-solid mb-2
                      ${(touched.password && errors.password && 'is-invalid') ||
                        (touched.password && !errors.password && 'is-valid')}`}
                  />
                  {touched.password && errors.password ? (
                    <div className="invalid-feedback">{errors.password}</div>
                  ) : null}
                  <span className="form-text text-muted">{intl.formatMessage({ id: 'PASS_REQUIREMENTS' })}</span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  {intl.formatMessage({ id: 'VERIFY_PASS' })}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <Field
                    type="password"
                    name="cPassword"
                    placeholder={intl.formatMessage({ id: 'VERIFY_PASS' })}
                    className={`form-control form-control-lg form-control-solid mb-2
                    ${(touched.cPassword && errors.cPassword && 'is-invalid') ||
                      (touched.cPassword && !errors.cPassword && 'is-valid')}`}
                  />
                  {touched.cPassword && errors.cPassword ? (
                    <div className="invalid-feedback">{errors.cPassword}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
