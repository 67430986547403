import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const ENDPOINT_API = REACT_APP_MODE === 'dev' ? 'https://api.atri-energo.ru/' : 'https://api.atri-energo.ru/';

const MY_LOGIN_URL = `${ENDPOINT_API}v1/profile/login`;
const MY_PROFILE_URL = `${ENDPOINT_API}v1/profile`;

const SEND_CODE_URL = `${ENDPOINT}api/app/auth.login?`;
const CHECK_CODE_URL = `${ENDPOINT}api/app/auth.send?`;

const GET_SETTINGS_URL = `${ENDPOINT}api/app/request.const`;
const CHANGE_PASS = `${ENDPOINT}api.php?type=app&method=changePassword&`;
const CHANGE_USER_INFO = `${ENDPOINT}api.php?type=app&method=userInfo&`;

export const requestCheckCode = async queryParameters => {
  return fetch(`${CHECK_CODE_URL}login=${btoa(queryParameters.phoneOrMail)}`).then(res => res.json());
};

export const getSettings = () => {
  const token = store.getState().auth.authToken;
  return fetch(`${GET_SETTINGS_URL}?&token=${token}`).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  });
};

export async function getUserByToken() {
  const token = store.getState().auth.authToken;
  const response = await fetch(`${MY_PROFILE_URL}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(resp => resp.json());
  return response;
}

export function checkPassword(password) {
  const token = store.getState().auth.authToken;
  return fetch(`${MY_LOGIN_URL}?type=app&method=passwordCheck&password=${btoa(password)}&token=${token}`, {
    method: 'POST',
    // body: formData,
  })
    .then(resp => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error();
      }
    })
    .then(respJson => {
      return respJson.success;
    });
}

export const changePass = ({ currentPassword, password, cPassword }) => {
  const token = store.getState().auth.authToken;

  return fetch(
    `${CHANGE_PASS}token=${token}&oldPassword=${btoa(currentPassword)}&newPassword=${btoa(
      password,
    )}&verifyPassword=${btoa(cPassword)}`,
  ).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  });
};

export const changeUserInfo = async (
  { name, surname, ochestvo, sex, birthday, phone, email },
  formAddress,
  formData,
) => {
  const token = store.getState().auth.authToken;

  return fetch(
    `${CHANGE_USER_INFO}token=${token}&name=${name}&surname=${surname}&ochestvo=${ochestvo}&sex=${sex}&birthday=${birthday}&place=${formAddress}&phone=${phone}&email=${email}`,
    {
      method: 'POST',
      body: formData,
    },
  ).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  });
};

export const recovery = values => {};

export async function getToken(queryParameters) {
  const { phoneEmail, password } = queryParameters;
  const formData = new FormData();
  formData.append('email', phoneEmail);
  formData.append('password', password);

  return fetch(`${MY_LOGIN_URL}`, {
    method: 'POST',
    body: formData,
  }).then(response => response.json());
}

export const sendCode = async queryParameters => {
  const { session, checkCodeLogin, checkCodeType } = store.getState().auth;
  const { code } = queryParameters;
  return fetch(
    `${SEND_CODE_URL}login=${btoa(checkCodeLogin)}&session=${session}&code=${code}&type=${checkCodeType}`,
  ).then(response => response.json());
};
