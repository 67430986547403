import * as requestFromServer from './myOrganisationsCrud';
import { myOrganisationsSlice, callTypes } from './myOrganisationsSlice';

const { actions } = myOrganisationsSlice;

export const fetchMyOrganisations = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyOrganisations(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.myOrganisationsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find myOrganisations!";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getAllMyOrganisations = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.listAll }));
  return requestFromServer
    .getAllMyOrganisations()
    .then(response => {
      const { entities } = response.data;
      dispatch(actions.allMyOrganisationsFetched({ entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't get all myOrganisations!";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.listAll }));
    });
};

export const fetchSaveMyOrganisations = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.saveOrg }));
  return requestFromServer
    .saveMyOrganisations(queryParams)
    .then(response => {
      dispatch(actions.saveMyOrganisationsFetched(response));
      dispatch(getAllMyOrganisations());
    })
    .catch(error => {
      error.clientMessage = "Can't save Organisations!";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.saveOrg }));
    });
};

export const fetchClearSaveResponse = () => dispatch => {
  dispatch(actions.clearSaveResponse());
};

export const clearLastCreatedOrgId = () => dispatch => {
  dispatch(actions.clearLastCreatedOrgId());
};

