import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_PROD_API, REACT_APP_REQ_DEV_API } = process.env;

const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

//получение данных для главной страницы
export const dashboardData = () => {
  const token = store.getState().auth.authToken;
  const response = fetch(`${ENDPOINT_API}v1/home`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  }).then(result => result.json());
  return response;
};
