import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../../_metronic/_helpers";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openVictoria, parentRow }

) => {
  return (
    <>
      <button
        title="Подобрать товар"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openVictoria(row, parentRow)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </button>
    </>
  );
};