import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

export const PromotionTable = ({ promotionEntities, handleOnSelect }) => {
  const columns = [
    {
      dataField: "preview",
      text: "",
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, row_) => {
        return (
          <div className="d-flex justify-content-center">
            <div className="symbol symbol-50 flex-shrink-0 mr-4">
              <div className="symbol-label" style={{ backgroundImage: `url(https://atri-energo.ru${cell})` }}></div>
            </div>
          </div>
        );
      },
    }, {
      dataField: "title",
      text: 'Наименование',
    }, {
      dataField: "sku",
      text: 'Артикул',
      align: 'center',
      headerAlign: 'center',
    }, {
      dataField: "price",
      text: 'Цена',
      align: 'center',
      headerAlign: 'center',
    }, {
      dataField: "discount_price",
      text: 'Цена по акции',
      align: 'center',
      headerAlign: 'center',
    }, {
      dataField: "count",
      text: 'Осталось',
      align: 'center',
      headerAlign: 'center',
    }
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      remote
      keyField="pos"
      data={promotionEntities}
      columns={columns}
      selectRow={{
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect,
        hideSelectAll: true
      }}
    >
    </BootstrapTable>
  );
};