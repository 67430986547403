import React, { useEffect, useMemo, useState } from 'react';
import SVG from "react-inlinesvg";
import { useScannerUIContext } from '../ScannerUIContext';
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const Step3 = ({ pdfFile }) => {
  const [numPages, setNumPages] = useState('');
  const scannerUIContext = useScannerUIContext();
  const scannerUIProps = useMemo(() => {
    return {
      scanFile: scannerUIContext.scanFile,
      setScanFile: scannerUIContext.setScanFile,
      formDataStep3: scannerUIContext.formDataStep3,
      setFormDataStep3: scannerUIContext.setFormDataStep3,
    };
  }, [scannerUIContext.formDataStep3, scannerUIContext.scanFile, scannerUIContext.setFormDataStep3, scannerUIContext.setScanFile]);

  useEffect(() => {
    const defaultCheckedPages = [];
    let num = numPages;

    for(let i = 1; i<=num; i++){
      defaultCheckedPages.push(i);
    }

    scannerUIProps.setFormDataStep3({
      ...scannerUIProps.formDataStep3,
      checkedPage: [...defaultCheckedPages],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPages]);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }

  const handleCheckPage = (page) => {
    const delIndex = scannerUIProps.formDataStep3.checkedPage.indexOf(page);

    if (delIndex === -1) {
      scannerUIProps.setFormDataStep3({
        ...scannerUIProps.formDataStep3,
        checkedPage: [...scannerUIProps.formDataStep3.checkedPage, page],
      });
      return;
    };

    const newChecked = [...scannerUIProps.formDataStep3.checkedPage];
    newChecked.splice(delIndex, 1);

    scannerUIProps.setFormDataStep3({
      ...scannerUIProps.formDataStep3,
      checkedPage: [...newChecked],
    });
  };

  const handleChangeAnyField = target => {
    scannerUIProps.setFormDataStep3({ ...scannerUIProps.formDataStep3, [target.name]: target.value });
  };

  const selects = <>
    <option value="1">1 столбец</option>
    <option value="2">2 столбец</option>
    <option value="3">3 столбец</option>
    <option value="4">4 столбец</option>
    <option value="5">5 столбец</option>
    <option value="6">6 столбец</option>
    <option value="7">7 столбец</option>
    <option value="8">8 столбец</option>
    <option value="9">9 столбец</option>
  </>;

  return (
    <>
      <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <h3 className="mb-10 font-weight-bold text-dark">Выбор страниц для отправки</h3>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label>Выберите страницы</label>
          <Document
            file={pdfFile}
            onLoadSuccess={onDocumentLoadSuccess}
            className="d-flex flex-wrap"
          >
            {Array.from({ length: numPages }, (_, index) => (
              <div className="text-center position-relative" key={index} onClick={()=>handleCheckPage(index + 1)}>
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  height={250}
                />
                <div className="position-absolute" style={{ 'top': 0, 'right': 0 }}>
                  {scannerUIProps.formDataStep3.checkedPage.includes(index + 1) ? <span className="svg-icon svg-icon-xl svg-icon-success">
                    <SVG src={"/media/svg/icons/Code/Done-circle.svg"} />
                  </span> : null}
                </div>
                <span>{index + 1}</span>
              </div>
            ))}
          </Document>
        </div>
        <div className="separator separator-dashed my-5"></div>

        <div className="form-group">
          <label>Номер столбца “Позиция”</label>
          <select
            name="pos"
            defaultValue="1"
            className="form-control form-control-solid form-control-lg"
            onChange={(e)=>handleChangeAnyField(e.target)}
          >
            <option value="0">Отсутствует</option>
            {selects}
          </select>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label>Номер столбца где расположено “Тип, марка, обозначение документа опросного листа”</label>
          <select
            name="title"
            className="form-control form-control-solid form-control-lg"
            defaultValue="2"
            onChange={(e)=>handleChangeAnyField(e.target)}
          >
            {selects}
          </select>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label>Номер столбца где расположено “Наименование и техническая характеристика”</label>
          <select
            name="marka"
            className="form-control form-control-solid form-control-lg"
            defaultValue="3"
            onChange={(e)=>handleChangeAnyField(e.target)}
          >
            <option value="0">Отсутствует</option>
            {selects}
          </select>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label>Номер столбца где расположено “Код оборудования, изделия, материала”</label>
          <select
            name="sku"
            className="form-control form-control-solid form-control-lg"
            defaultValue="4"
            onChange={(e)=>handleChangeAnyField(e.target)}
          >
            {selects}
          </select>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label>Номер столбца где расположено “Завод изготовитель”</label>
          <select
            name="man"
            className="form-control form-control-solid form-control-lg"
            defaultValue="5"
            onChange={(e)=>handleChangeAnyField(e.target)}
          >
            <option value="0">Отсутствует</option>
            {selects}
          </select>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label>Номер столбца где расположено “Единица измерения”</label>
          <select
            name="unit"
            className="form-control form-control-solid form-control-lg"
            defaultValue="6"
            onChange={(e)=>handleChangeAnyField(e.target)}
          >
            <option value="0">Отсутствует</option>
            {selects}
          </select>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label>Номер столбца где расположено “Количество”</label>
          <select
            name="count"
            className="form-control form-control-solid form-control-lg"
            defaultValue="7"
            onChange={(e)=>handleChangeAnyField(e.target)}
          >
            {selects}
          </select>
        </div>
      </div>
    </>
  );
};