/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';

import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import './NotifiWidgetRow.scss';

export const NotifiWidgetRow = ({ entity }) => {
  const [myText, setMyText] = useState('');
  const { url, title, text, icon, date } = entity;

  useEffect(() => {
    if (text.length < 45) {
      setMyText(text);
      return;
    }

    setMyText(text.slice(0, 45) + '...');
  }, [text]);

  const viewNotifi = useCallback(() => {
    window.open(url);
  }, [url]);

  return (
    <div className="d-flex flex-wrap align-items-center mb-6">
      <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mx-auto">
        <div
          className="symbol-label my-symbol-label"
          style={{
            backgroundImage: `url('${toAbsoluteUrl(icon)}')`,
          }}
        ></div>
      </div>
      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3 ml-3">
        <a
          className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
          onClick={()=>viewNotifi()}
        >
          {title}
        </a>
        <span className="text-muted font-weight-bold font-size-sm my-1">
          {myText}
        </span>
        <span className="text-muted font-weight-bold font-size-sm">
            Дата:{" "}
          <span className="text-primary font-weight-bold">{date}</span>
        </span>
      </div>
    </div>
  )
};