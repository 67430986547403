import React from 'react';
import { useIntl } from 'react-intl';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export function FooterCompact({ today, footerClasses, footerContainerClasses }) {
  const intl = useIntl();
  return (
    <>
      <div className={`footer bg-white py-4 d-flex flex-lg-column  ${footerClasses}`} id="kt_footer">
        <div
          className={`${footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">
              Copyright &copy; 2012-{today} - Все права защищены!
            </span>
          </div>
          <div className="nav nav-dark order-1 order-md-2">
            <a href={`${ENDPOINT}feedback`} target="_blank" rel="noopener noreferrer" className="nav-link pl-3 pr-0">
              {intl.formatMessage({ id: 'AUTH.GENERAL.CONTACT' })}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
