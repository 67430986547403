import { createSlice } from '@reduxjs/toolkit';

const initialScannerState = {
  listLoading: false,
  scanLoading: false,
  checkedScanLoading: false,
  getScanLoading: false,
  getProductLoading: false,
  ordersEntities: [],
  decodedScanList: [],
  foundedScanList: [],
  lastError: null,
  pdfUrl: null,
  pdfFile: null,
};
export const callTypes = {
  list: 'list',
  scan: 'scan',
  checkedScan: 'checkedScan',
  getScanFile: 'getScanFile',
  getProduct: 'getProduct',
  sendProduct: 'sendProduct',
};

export const scannerSlice = createSlice({
  name: 'notifications',
  initialState: initialScannerState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      }

      if (action.payload.callType === callTypes.scan) {
        state.scanLoading = false;
      }

      if (action.payload.callType === callTypes.checkedScan) {
        state.checkedScanLoading = false;
      }

      if (action.payload.callType === callTypes.getScanFile) {
        state.getScanLoading = false;
      }

      if (action.payload.callType === callTypes.getProduct) {
        state.getProductLoading = false;
      }

      if (action.payload.callType === callTypes.sendProduct) {
        state.sendProductLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      }

      if (action.payload.callType === callTypes.scan) {
        state.scanLoading = true;
      }

      if (action.payload.callType === callTypes.checkedScan) {
        state.checkedScanLoading = true;
      }

      if (action.payload.callType === callTypes.getScanFile) {
        state.getScanLoading = true;
      }

      if (action.payload.callType === callTypes.getProduct) {
        state.getProductLoading = true;
      }

      if (action.payload.callType === callTypes.sendProduct) {
        state.sendProductLoading = true;
      }
    },

    allOrdersFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.ordersEntities = entities;
    },

    scanFetched: (state, action) => {
      const { type, source } = action.payload;
      if (type !== 'error') {
        state.pdfUrl = source;
      }
      state.scanLoading = false;
      state.error = null;
    },

    getScanFetched: (state, action) => {
      state.pdfFile = action.payload;
      state.getScanLoading = false;
      state.error = null;
    },

    checkedScanFetched: (state, action) => {
      const { data, founded, type } = action.payload;
      if (type !== 'error') {
        state.decodedScanList = data;
        state.foundedScanList = founded;
      }
      state.checkedScanLoading = false;
      state.error = null;
    },

    getProductByCIDFetched: state => {
      state.getProductLoading = false;
      state.error = null;
    },

    setScanList: (state, action) => {
      const { pos, title, cid, man, marka, sku, unit } = action.payload.product;
      const list = [...state.foundedScanList];
      const index = list.findIndex(item => item.pos === pos);
      list[index] = { pos, title, cid, man, marka, sku, unit };
      state.foundedScanList = [...list];
      state.error = null;
    },

    sendSelectedProductsFetched: (state, action) => {
      state.sendProductLoading = true;
    },
  },
});
