import { createSlice } from '@reduxjs/toolkit';

const initialDashboardState = {
  loading: false,
  entities: null,
  error: null,
};

export const callTypes = {
  dashData: 'dashData',
};

export const homeSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
    },

    startCall: state => {
      state.error = null;
      state.loading = true;
    },

    dashboardFetched: (state, action) => {
      const { data } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities = data;
    },
  },
});
