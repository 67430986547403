import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';

import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ROUTES } from '../../Routes.models';

export const PromoWidgetRow = ({ entity }) => {
  const [myText, setMyText] = useState('');
  const { id, title, description, preview, date_start } = entity;

  const history = useHistory();

  useEffect(() => {
    if (description.length < 45) {
      setMyText(description);
      return;
    }

    setMyText(description.slice(0, 45) + '...');
  }, [description]);

  const viewPromo = useCallback(() => {
    history.push(`${ROUTES.PROMOTIONS}/${id}`);
  }, [history, id]);

  return (
    <div className="d-flex flex-wrap align-items-center mb-6">
      <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mx-auto">
        <div
          className="symbol-label my-symbol-label"
          style={{
            backgroundImage: `url('${toAbsoluteUrl(preview.source)}')`,
          }}
        ></div>
      </div>
      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3 ml-3">
        <span
          className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg cursor-pointer"
          onClick={()=>viewPromo()}
        >
          {title}
        </span>
        <span className="text-muted font-weight-bold font-size-sm my-1">
          {myText}
        </span>
        <span className="text-muted font-weight-bold font-size-sm">
          Дата:{" "}
          <span className="text-primary font-weight-bold">
            {moment(date_start).format('DD.MM.YYYY hh:mm')}
          </span>
        </span>
      </div>
    </div>
  )
};