import React from 'react';
import { DispatchingCard } from './DispatchingCard';
import { DispatchingUIProvider } from './DispatchingUIContext';

export const DispatchingPage = () => {
  return (
    <DispatchingUIProvider>
      <DispatchingCard />
    </DispatchingUIProvider>
  );
};