import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export const NOTIFICATION_URL = `${ENDPOINT}api/app/notif.get?`;

export async function findUnreadNotifi(queryParams) {
  const token = store.getState().auth.authToken;
  const filter = JSON.stringify(queryParams.filter);

  return fetch(
    `${NOTIFICATION_URL}filter=${filter}&limit=${queryParams.limit}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}&token=${token}`,
  ).then(res => res.json());
}
