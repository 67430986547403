import React, { useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useScannerUIContext } from '../../../../ScannerUIContext';
import { THIRD_STEP_TABLE_CLASSES } from '../../../../ScannerUIHelpers';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';

export const InnerTable = ({ row }) => {
  const { currentState } = useSelector(state => ({ currentState: state.scanner }));
  const { foundedScanList } = currentState;

  const scannerUIContext = useScannerUIContext();
  const scannerUIProps = useMemo(() => {
    return {
      victoriaRun: scannerUIContext.victoriaRun,
    };
  }, [scannerUIContext.victoriaRun]);

  const columns = [
    {
      dataField: "pos",
      text: "",
      classes: function(cell, row) {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      headerStyle: () => ({ display: 'none' }),
    }, {
      dataField: "title",
      text: 'Тип, марка, обозначение',
      classes: function(cell, row) {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      formatter: function(cell, row) {return row?.[this.dataField]},
      headerStyle: () => ({ display: 'none' }),
    }, {
      dataField: "marka",
      text: 'Наименование и техническая характеристика',
      align: 'center',
      headerAlign: 'center',
      classes: function(cell, row) {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      formatter: function(cell, row) {return row?.[this.dataField]},
      headerStyle: () => ({ display: 'none' }),
    }, {
      dataField: "sku",
      text: 'Код оборудования, изделия, материала',
      align: 'center',
      headerAlign: 'center',
      classes: function(cell, row) {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      formatter: function(cell, row) {return row?.[this.dataField]},
      headerStyle: () => ({ display: 'none' }),
    }, {
      dataField: "unit",
      text: 'Единица измерения',
      align: 'center',
      headerAlign: 'center',
      classes: function(cell, row) {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      formatter: function(cell, row) {return row?.[this.dataField]},
      headerStyle: () => ({ display: 'none' }),
    }, {
      dataField: 'action',
      text: 'Actions',
      formatter: ActionsColumnFormatter,
      formatExtraData: { openVictoria: scannerUIProps.victoriaRun, parentRow: row },
      headerStyle: () => ({ display: 'none' }),
    },
  ];

  return (
    <div className="">
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="pos"
        data={[foundedScanList.find(i=>i.pos===row.pos)]}
        columns={columns}
        bodyClasses={'bg-light-primary'}
      >
      </BootstrapTable>
    </div>
  )
};