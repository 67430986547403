import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './MyOrganisationsUIHelpers';

const MyOrganisationsUIContext = createContext();

export function useMyOrganisationsUIContext() {
  return useContext(MyOrganisationsUIContext);
}

export const MyOrganisationsUIConsumer = MyOrganisationsUIContext.Consumer;

export function MyOrganisationsUIProvider({ myOrganisationsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCalculation = {
    id: undefined,
    date: '',
    object: '',
    oid: '',
    status: '',
    title: '',
    type: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCalculation,
  };

  return <MyOrganisationsUIContext.Provider value={value}>{children}</MyOrganisationsUIContext.Provider>;
}
