import { createSlice } from '@reduxjs/toolkit';

const initialOrdersState = {
  listLoading: false,
  nevOrderSaving: false,
  addingToArhive: false,
  gettingFromArchive: false,
  totalCount: 0,
  entities: null,
  orderForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: 'list',
  newOrder: 'newOrder',
  addToArhive: 'addToArhive',
  getFromArhive: 'getFromArhive',
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrdersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.newOrder) {
        state.nevOrderSaving = false;
        return;
      }

      if (action.payload.callType === callTypes.addToArhive) {
        state.addingToArhive = false;
        return;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.newOrder) {
        state.nevOrderSaving = true;
        return;
      }

      if (action.payload.callType === callTypes.addToArhive) {
        state.addingToArhive = true;
        return;
      }
    },
    ordersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    orderSaved: (state, action) => {
      state.nevOrderSaving = false;
    },

    addToArchive: (state, action) => {
      state.addingToArhive = false;
      state.error = null;
    },

    getFromArchive: (state, action) => {
      state.gettingFromArchive = false;
      state.error = null;
    }
  },
});
