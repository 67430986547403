import { createSlice } from '@reduxjs/toolkit';

const initialCalculationTOState = {
  listLoading: false,
  actionsLoading: false,
  saveCalcLoading: false,
  lastError: null,
  attachId: [],
  saveCalculationNum: null,
  saveResponseType: '',
  entities: null,
  userSavedEntitiesIds: [],
  errorsList: null,
  responseType: null,
};
export const callTypes = {
  list: 'list',
  action: 'action',
  saveCalc: 'save',
};

export const calculationTOSlice = createSlice({
  name: 'calculationTO',
  initialState: initialCalculationTOState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      }
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      }
      if (action.payload.callType === callTypes.saveCalc) {
        state.saveCalcLoading = false;
        state.saveResponseType = 'failure';
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.attachId = null;
      state.saveCalculationNum = null;
      state.saveResponseType = null;
      state.responseType = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        return;
      }
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
        return;
      }
      if (action.payload.callType === callTypes.saveCalc) {
        state.saveCalcLoading = true;
        return;
      }
    },

    calculationTOFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userSavedEntitiesIds = initialCalculationTOState.userSavedEntitiesIds;

      if (data.type === 'success') {
        state.entities = [...data.entities];
        state.responseType = data.type;
        return;
      }

      if (data.type === 'error') {
        state.entities = [];
        state.responseType = data.type;
        state.errorsList = { ...data.error };
        return;
      }

      if (data.type === 'empty') {
        state.entities = [];
        state.responseType = data.type;
        state.errorsList = null;
        return;
      }
    },

    saveCalculationTOFetched: (state, action) => {
      const { data } = action.payload;
      state.saveCalcLoading = false;
      state.error = null;

      if (data.type === 'success') {
        state.attachId = [...data.aid];
        state.saveResponseType = data.type;
        state.saveCalculationNum = data.request;
        return;
      }
    },

    clearSaveResponseType: state => {
      state.saveResponseType = initialCalculationTOState.saveResponseType;
      state.attachId = initialCalculationTOState.attachId;
      state.saveCalculationNum = initialCalculationTOState.saveCalculationNum;
    },

    saveSavedCalcId: (state, action) => {
      state.userSavedEntitiesIds = [...state.userSavedEntitiesIds, action.payload.id];
    },
  },
});
