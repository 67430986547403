/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment/min/moment-with-locales';
import React, { useCallback } from "react";
import { useLang } from "../../../_metronic/i18n";

export const UnreadNotifiRow = ({ title, icon, date, url, HandlerDropdown }) => {
  const lang = useLang();
  (lang === "ru") ? moment.locale('ru') : moment.locale('en-gb');

  const handlerUnreadNotifi = useCallback(()=>{
    HandlerDropdown();
    window.open(url);
  }, [HandlerDropdown, url]
  );

  return (
    <a href="#" className="navi-item" onClick={() => handlerUnreadNotifi()}>
      <div className="navi-link">
        <div className="navi-icon mr-2">
          <img src={icon} alt="icon" width="30px" height="30px"></img>
        </div>
        <div className="navi-text">
          <div className="font-weight-bold">
            {title}
          </div>
          <div className="text-muted">
            {moment(date).fromNow()}
          </div>
        </div>
      </div>
    </a>
  );
};