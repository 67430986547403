import * as requestFromServer from './myObjectsCrud';
import { myObjectsSlice, callTypes } from './myObjectsSlice';

const { actions } = myObjectsSlice;

export const fetchMyObjects = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findMyObjects(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.myObjectsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find myObjects!";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getAllMyObjects = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.listAll }));
  return requestFromServer
    .getAllMyObjects()
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.allMyObjectsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't get all myObjects!";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.listAll }));
    });
};

export const fetchSaveMyObjects = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.saveObject }));
  return requestFromServer
    .saveMyObjects(queryParams)
    .then(response => {
      dispatch(actions.saveMyObjectsFetched(response));
      dispatch(getAllMyObjects());
    })
    .catch(error => {
      error.clientMessage = "Can't save MyObjects!";
      console.log(error);
      dispatch(actions.catchError({ error, callType: callTypes.saveObject }));
    });
};

export const fetchClearSaveResponse = () => dispatch => {
  dispatch(actions.clearSaveResponse());
};

export const clearLastCreatedObjectId = () => dispatch => {
  dispatch(actions.clearLastCreatedObjectId());
};

