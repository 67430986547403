/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useCallback, useState } from 'react';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import { BreadCrumbs } from './components/BreadCrumbs';
import { getBreadcrumbsAndTitle, useSubheader } from '../../_core/MetronicSubheader';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { ModalReport } from '../../../../app/widgets/modalReport/ModalReport';
import { titleFilter } from '../../../_helpers/TitleFilter';

export function SubHeader() {
  const [showReportsModal, setShowReportsModal] = useState(false);
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(uiService.config, 'subheader.mobile-toggle'),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses('subheader_container', true),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname);
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname);
    const breadcrumbs = header && header.breadcrumbs.length > 0 ? header.breadcrumbs : aside.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0 ? titleFilter(aside.title) : titleFilter(header.title),
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  const handlerReports = useCallback(() => {
    setShowReportsModal(true);
  }, []);

  return (
    <div id="kt_subheader" className={`subheader py-2 py-lg-12   ${layoutProps.subheaderCssClasses}`}>
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          <div className="d-flex flex-column">
            <h2 className="text-white font-weight-bold my-2 mr-5">{subheader.title}</h2>
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <button
            type="button"
            disabled={false}
            className="btn btn-transparent-white font-weight-bold  py-3 px-6 mr-4"
            onClick={() => handlerReports()}
          >
            Нашли ошибку?
          </button>
          {/* <QuickActions /> */}
        </div>
      </div>
      <ModalReport showReportsModal={showReportsModal} setShowReportsModal={setShowReportsModal} />
    </div>
  );
}
