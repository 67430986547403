import * as requestFromServer from './dispatchingCrud';
import { dispatchingSlice, callTypes } from './dispatchingSlice';

const { actions } = dispatchingSlice;

export const getDispObjects = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getDispObjts }));
  return requestFromServer
    .getDispObjects(queryParams)
    .then(response => {
      const { entities, totalCount } = response.data;
      dispatch(actions.allDispatchingObjFetched({ entities, totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't get all dispatching objects!";
      dispatch(actions.catchError({ error, callType: callTypes.getDispObjts }));
    });
};

export const getDispObject = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getDispObjt }));
  return requestFromServer
    .getDispObject(queryParams)
    .then(response => {
      const { entity } = { entity: response.data };
      dispatch(actions.dispatchingObjFetched({ entity }));
    })
    .catch(error => {
      error.clientMessage = "Can't get dispatching object!";
      dispatch(actions.catchError({ error, callType: callTypes.getDispObjt }));
    });
};

export const getDispObjectVar = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getDispObjtVar }));
  return requestFromServer
    .getDispObjectVar(queryParams)
    .then(response => {
      const { entities } = { entities: response.tags };
      dispatch(actions.dispatchingObjVarFetched({ entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't get dispatching object variables!";
      dispatch(actions.catchError({ error, callType: callTypes.getDispObjtVar }));
    });
};
