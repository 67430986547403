/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import moment from 'moment/min/moment-with-locales';
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card, CardBody, CardFooter, CardHeader } from '../../../_metronic/_partials/controls';
import * as actions from './redux/reportsActions';

import { CardSpinner } from '../../widgets/cardSpinner/CardSpinner';
import { ModalReport } from '../../widgets/modalReport/ModalReport';
import { alphabetColor } from '../../../_metronic/_helpers/index';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export const ReportsPage = () => {
  const [reportMsgInput, setReportMsgInput] = useState('');
  const [showReportsModal, setShowReportsModal] = useState(false);
  const [openReportId, setOpenReportId] = useState(0);
  const [openReportTitle, setOpenReportTitle] = useState('');
  const [openReportStatus, setOpenReportStatus] = useState(null);

  const perfectScrollMsgsRef = useRef(null);
  const perfectScrollReportsRef = useRef(null);

  const dispatch = useDispatch();

  const { currentState, authState } = useSelector(state => ({
    currentState: state.reports,
    authState: state.auth
  }));

  const { reportLoading, reportMsgsLoading, reportMsgCreating, reportCreating, entitiesReports, entitiesMsgs } = currentState;
  const { user } = authState;

  const clickCreateReportHandler = async () => {
    setShowReportsModal(true);
  };

  useEffect(() => {
    dispatch(actions.fetchShowReports());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    msgsScrollDown();
  }, [entitiesMsgs]);

  useEffect(() => {
    reportsScrollUp();
  }, [entitiesReports]);

  useEffect(() => {
    return dispatch(actions.fetchClearReportMsgs());
  }, [dispatch]);

  useEffect(() => {
    if (openReportId) {
      dispatch(actions.fetchShowMsgsReports({ id: openReportId }));
    }
  }, [dispatch, openReportId]);

  const clickOpenReportHandler = target => {
    if (target.dataset.id !== openReportId) {
      setOpenReportId(target.dataset.id);
      setOpenReportTitle(target.dataset.title);
      setOpenReportStatus(target.dataset.status);
    }
  };

  const msgsScrollDown = () => perfectScrollMsgsRef.current._container.scrollTop = perfectScrollMsgsRef.current._container.scrollHeight;
  const reportsScrollUp = () => perfectScrollReportsRef.current._container.scrollTop = 0;

  const handleSubmitReportMsg = useCallback(() => {
    if (reportMsgInput.trim()) {
      dispatch(actions.fetchCreateMsgsReport({ support_id: openReportId, message: reportMsgInput }))
        .then(() => dispatch(actions.fetchShowMsgsReports({ id: openReportId })))
        .then(() => setReportMsgInput(''));
      return;
    }

    setReportMsgInput('')
  }, [dispatch, openReportId, reportMsgInput]);

  const handleSendFile = useCallback(e => {
    e.persist();
    const file = e.target.files[0];
    dispatch(actions.fetchCreateFileReport({ support_id: openReportId, picture: file }))
      .then(() => dispatch(actions.fetchShowMsgsReports({ id: openReportId })));
  }, [dispatch, openReportId]);

  return (
    <>
      {(reportLoading || reportMsgsLoading || reportMsgCreating || reportCreating) && <CardSpinner />}
      <ModalReport showReportsModal={showReportsModal} setShowReportsModal={setShowReportsModal} />
      <div className="row">
        <div className="col-4">
          <Card id="reports-card-list" className="">
            <CardHeader title="Список ваших обращений">
            </CardHeader>
            <CardBody>
              <PerfectScrollbar
                ref={perfectScrollReportsRef}
                options={{
                  suppressScrollX: "false"
                }}
                style={{ maxHeight: '570px' }}
              >
                <div className="mr-3">
                  {[...entitiesReports].reverse().map(report => (
                    <div key={report.id} className={`d-flex align-items-center justify-content-between p-3 rounded ${openReportId === report.id && 'bg-light-secondary'}`}>
                      <div className="d-flex align-items-center">
                        <div className={`btn btn-icon btn-sm mr-3 px-md-2 bg-${alphabetColor(report.title)} cursor-default`}>
                          <span className="symbol symbol-35">
                            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{report.title[0]}</span>
                          </span>
                        </div>
                        <div className="d-flex flex-column">
                          <a data-id={report.id} data-title={report.title} data-status={report.status} onClick={e => clickOpenReportHandler(e.target)} className="text-dark-75 text-hover-primary font-weight-bold font-size-lg">
                            {report.title.length > 30 ? report.title.slice(0, 30) + '...' : report.title}
                          </a>
                          <div className="">
                            {report.status === '1' ? (
                              <>
                                <span className="label label-sm label-dot label-success mr-1"></span>
                                <span className="text-muted font-weight-bold font-size-sm">
                                  Открытая
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="label label-sm label-dot label-danger mr-1"></span>
                                <span className="text-muted font-weight-bold font-size-sm">
                                  Закрытая
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span className="text-muted font-weight-bold font-size-sm">
                          {moment(report.created_at).fromNow()}
                        </span>
                        <span className="label label-sm label-success">{report.unread_messages}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </PerfectScrollbar>
            </CardBody>
            <CardFooter className="text-right">
              <button
                type="button"
                className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                onClick={()=>clickCreateReportHandler()}
              >
                Создать заявку
              </button>
            </CardFooter>
          </Card>
        </div>
        <div className="col-8">
          <Card id="reports-card-answers" className="card-stretch">
            <CardHeader>
              {openReportId ? <>
                <div className="text-center flex-grow-1 px-4 py-3">
                  <div className="text-dark-75 font-weight-bold font-size-h5">{openReportTitle}</div>
                  {openReportStatus === '1' ? <div>
                    <span className="label label-sm label-dot label-success mr-3"></span>
                    <span className="font-weight-bold text-muted font-size-sm">Открытая</span>
                  </div> : <div>
                    <span className="label label-sm label-dot label-danger"></span>
                    <span className="font-weight-bold text-muted font-size-sm">Закрытая</span>
                  </div>}
                </div>
              </> : (
                <div className="d-flex justify-content-center align-items-center w-100">
                  <span className="text-dark-75 font-weight-bold font-size-h5">Нет выбранных обращений</span>
                </div>
              )}
            </CardHeader>
            <CardBody>
              <PerfectScrollbar
                ref={perfectScrollMsgsRef}
                options={{
                  suppressScrollX: "false"
                }}
                style={{ maxHeight: '500px' }}
              >
                <div className="mr-3">
                  {entitiesMsgs.map(msg => (
                    msg.uid !== Number(user.id) ? (
                      <div key={msg.id} className="d-flex flex-column mb-5 align-items-start">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-circle symbol-40 mr-3">
                            <img alt="Pic" src={`${ENDPOINT}${msg.avatar}`} />
                          </div>
                          <div>
                            <a href="#" className="mr-1 text-dark-75 text-hover-primary font-weight-bold font-size-h6">{msg.full_name}{' '}</a>
                            <span className="text-muted font-size-sm">{moment(msg.created_at).fromNow()}</span>
                          </div>
                        </div>
                        {
                          msg.file ? (
                            <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-600px">
                              <a href={msg.file.url} target="_blank" rel="noopener noreferrer" className="svg-icon svg-icon-xxl svg-icon-success">
                                <SVG src={"/media/svg/icons/Files/File.svg"} />
                                {msg.file.name}
                              </a>
                            </div>
                          ) : (
                            <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-600px">
                              {msg.message}
                            </div>
                          )
                        }
                      </div>
                    ) : (
                      <div key={msg.id} className="d-flex flex-column mb-5 align-items-end">
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="text-muted font-size-sm">{moment(msg.created_at).fromNow()}</span>
                            <a href="#" className="ml-2 text-dark-75 text-hover-primary font-weight-bold font-size-h6">Вы</a>
                          </div>
                          <div className="symbol symbol-circle symbol-40 ml-3">
                            <img alt="Pic" src={user.avatar} />
                          </div>
                        </div>
                        {
                          msg.file ? (
                            <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-600px">
                              <a href={msg.file.url} target="_blank" rel="noopener noreferrer" className="svg-icon svg-icon-xxl svg-icon-success 11">
                                <SVG src={"/media/svg/icons/Files/File.svg"} />
                                {msg.file.name}
                              </a>
                            </div>
                          ) : (
                            <div className="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-600px">
                              {msg.message}
                            </div>
                          )
                        }
                      </div>
                    )
                  ))}
                </div>
              </PerfectScrollbar>
            </CardBody>
            <CardFooter className="text-right">
              {openReportId ? <>
                <textarea className="form-control border-0 p-0" rows="2" value={reportMsgInput} onChange={(e)=>setReportMsgInput(e.target.value)} placeholder="Напищите сообщение..."></textarea>
                <div className="d-flex align-items-center justify-content-between mt-5">
                  <div className="mr-3">
                    <label
                      className="btn btn-clean btn-icon btn-md mr-1"
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i className="flaticon2-photograph icon-lg"></i>
                      <input
                        style={{ width: '0px', opacity: 0 }}
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        onChange={e => handleSendFile(e)}
                      />
                    </label>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                      onClick={handleSubmitReportMsg}
                    >
                      Отправить
                    </button>
                  </div>
                </div>
              </> : null}
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};