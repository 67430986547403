import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

import { useScannerUIContext } from '../ScannerUIContext';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export const Step2 = () => {
  const scannerUIContext = useScannerUIContext();
  const scannerUIProps = useMemo(() => {
    return {
      scanFile: scannerUIContext.scanFile,
      setScanFile: scannerUIContext.setScanFile,
    };
  }, [scannerUIContext.scanFile, scannerUIContext.setScanFile]);

  const handleCheckScanFile = e => {
    e.persist();
    scannerUIProps.setScanFile({ ...scannerUIProps.scanFile, file: e.target.files[0] });
  };

  return (
    <>
      <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <h3 className="mb-10 font-weight-bold text-dark">Выбор документа</h3>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label className="w-100">Выберите документ который вы хотите распознать</label>
          <div className="mb-3">
            <label
              className="w-50 btn btn-primary btn-sm btn-elevate px-9 form-control form-control-solid form-control-lg mb-0"
              data-action="change"
              data-toggle="tooltip"
              title=""
            >
              <input
                style={{ width: '0px', opacity: 0 }}
                type="file"
                name="scan"
                accept=".pdf, .xls, .xlsx, .doc, .docx, .jpg, .png"
                disabled={false}
                onChange={e => handleCheckScanFile(e)}
              />
              <span className="align-bottom">Выбрать фаил для загрузки</span>
            </label>
            <span className="ml-3">{scannerUIProps.scanFile?.file?.name ?? null}</span>
          </div>
          <span className="form-text text-muted mb-3">
            <b>Требование к файлу:</b>
            <br />
            Поддерживаемые форматы PDF/XLS/XLSX/DOC/DOCX/JPG/PNG.
            <br />
            Разрешение 300 DPI и выше.
          </span>
          <div className="d-flex flex-column rounded p-2 bg-light-primary text-dark-50 font-weight-bold font-size-lg max-w-250px mb-3">
            <a
              href={`${ENDPOINT}/download/51518`}
              target="_blank"
              rel="noopener noreferrer"
              className="svg-icon svg-icon-xxl svg-icon-success"
            >
              <SVG src={'/media/svg/icons/Files/File.svg'} />
              Образец фаила Excel.xls
            </a>
          </div>
          <span className="form-text text-muted">
            Внимание, если документ будет сильно отличаться от образца то возможно некорректное распознавание, также при
            низком разрешение существует вероятность ошибочного распознавания!
          </span>
        </div>
      </div>
    </>
  );
};
