import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';

import * as action from  '../_redux/authActions';
import { ROUTES } from '../../../Routes.models';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const initialValues = {
  phoneOrMail: '',
};

export const RegistrationV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    phoneOrMail: Yup.string()
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      dispatch(action.fetchCheckCode(values))
        .then(()=>{
          disableLoading();
          history.push('/auth/checkCode');
        });
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-left">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold font-size-h6">{intl.formatMessage({ id: 'ENTER_OR_REGISTER' })}</p>
      </div>
      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="form-group fv-plugins-icon-container">
          <label className="col-form-label text-center">
            <p className="font-weight-bold font-size-h6">Телефон или E-mail</p>
          </label>
          <div className="d-flex">

            <input
              placeholder="Phone or mail"
              className={`form-control form-control-solid h-auto py-5 px-6 mr-2 ${getInputClasses('phoneOrMail')}`}
              name="phoneOrMail"
              autoComplete="on"
              {...formik.getFieldProps('phoneOrMail')}
            />

            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="btn btn-success font-weight-bold py-5 px-6 no-wrap"
            >
              <span>Получить код</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>

          </div>
          {formik.touched.phoneOrMail && formik.errors.phoneOrMail ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phoneOrMail}</div>
            </div>
          ) : null}
        </div>

        <div className="text-left mb-10 mb-lg-20">
          <p className="font-weight-bold font-size-h8">Нажимая кнопку "Получить код", Вы соглашаетесь с условиями политики конфиденциальности.</p>
        </div>

        <div className="form-group d-flex flex-wrap">
          <Link to={ROUTES.AUTH_LOGIN} className="btn btn-primary font-weight-bold px-6 py-4 my-3 mx-4">
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Key.svg')} />
            </span>
            Войти с паролем
          </Link>
        </div>
      </form>
    </div>
  );
};