const defColors = ['primary', 'warning', 'success', 'info', 'danger'];

export const alphabetColor = string => {
  if (typeof string !== 'string') {
    return defColors[4];
  }

  const code = Number(string.codePointAt(0));

  if ((code >= 65 && code <= 71) || (code >= 1040 && code <= 1046)) {
    return defColors[0];
  }

  if ((code >= 97 && code <= 103) || (code >= 1097 && code <= 1103)) {
    return defColors[1];
  }

  if ((code >= 72 && code <= 77) || (code >= 1047 && code <= 1052)) {
    return defColors[2];
  }

  if ((code >= 104 && code <= 109) || (code >= 1072 && code <= 1080)) {
    return defColors[3];
  }

  if ((code >= 78 && code <= 84) || (code >= 1053 && code <= 1062)) {
    return defColors[0];
  }

  if ((code >= 110 && code <= 116) || (code >= 1081 && code <= 1089)) {
    return defColors[1];
  }
  if ((code >= 85 && code <= 90) || (code >= 1063 && code <= 1071)) {
    return defColors[2];
  }

  if ((code >= 117 && code <= 122) || (code >= 1090 && code <= 1096)) {
    return defColors[3];
  }

  return defColors[4];
};
