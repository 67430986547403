import store from '../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const SCAN_SEND = `${ENDPOINT}api/app/scan.file?`;
const SCAN_SEND_CHECKED_PAGE = `${ENDPOINT}api/app/scan.do?`;
const SCAN_GET_FILE = `${ENDPOINT}api/app/scan.pdf?`;
const GET_PRODUCT_BY_CID = `${ENDPOINT}api/app/scan.catalog?`;
const SEND_CHECKED_PRODUCTS = `${ENDPOINT}api/app/scan.save?`;

export const getProductByCID = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return await fetch(`${GET_PRODUCT_BY_CID}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());
};

export const scanSend = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return await fetch(`${SCAN_SEND}&token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());
};

export const getScanFile = async queryParams => {
  const token = store.getState().auth.authToken;

  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return await fetch(`${SCAN_GET_FILE}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.blob());
};

export const scanCheckedPagesSend = async queryParams => {
  const token = store.getState().auth.authToken;

  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return await fetch(`${SCAN_SEND_CHECKED_PAGE}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());
};

export const sendSelectedProducts = async queryParams => {
  const token = store.getState().auth.authToken;

  const { products, ...rest } = queryParams;

  const formData = new FormData();

  products.forEach(item => {
    formData.append('products[]', JSON.stringify(item));
  });

  for (let key in rest) {
    formData.append(key, queryParams[key]);
  }

  return await fetch(`${SEND_CHECKED_PRODUCTS}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());
};
