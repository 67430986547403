/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from '../../../_helpers';

export function TilesWidget11({
  className,
  baseColor = 'primary',
  widgetHeight = '150px',
  counter = '999999',
  text = 'New Products',
  href = '',
}) {
  const history = useHistory();

  const redirect = useCallback(() => {
    history.push(href);
  }, [history, href]);

  return (
    <>
      <div className={`card card-custom bg-${baseColor} ${className}`} style={{ height: widgetHeight }}>
        <div onClick={() => redirect()} className="card-body cursor-pointer">
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
            <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Calculator.svg')} />
          </span>
          <div className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}>{counter}</div>
          <a className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1 text-hover-info`}>{text}</a>
        </div>
      </div>
    </>
  );
}
