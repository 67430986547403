import { createSlice } from '@reduxjs/toolkit';

const initialDispatchingState = {
  dispObjtsLoading: false,
  dispObjtLoading: false,
  getDispObjtVarLoading: false,
  dispObjtsTotalCount: 0,
  dispObjtsEntities: [],
  dispObjtEntity: {},
  dispObjVarEntities: [],
  error: null,
};
export const callTypes = {
  getDispObjts: 'getDispObjts',
  getDispObjt: 'getDispObjt',
  getDispObjtVar: 'getDispObjtVar',
};

export const dispatchingSlice = createSlice({
  name: 'dispatching',
  initialState: initialDispatchingState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.getDispObjts) {
        state.dispObjtsLoading = false;
      }

      if (action.payload.callType === callTypes.getDispObjt) {
        state.dispObjtLoading = false;
      }

      if (action.payload.callType === callTypes.getDispObjtVar) {
        state.getDispObjtVarLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.getDispObjts) {
        state.dispObjtsLoading = true;
      }

      if (action.payload.callType === callTypes.getDispObjt) {
        state.dispObjtLoading = true;
      }

      if (action.payload.callType === callTypes.getDispObjtVar) {
        state.getDispObjtVarLoading = true;
      }
    },

    allDispatchingObjFetched: (state, action) => {
      const { entities, totalCount } = action.payload;
      state.dispObjtsLoading = false;
      state.error = null;
      state.dispObjtsEntities = entities;
      state.dispObjtsTotalCount = totalCount;
      state.error = null;
    },

    dispatchingObjFetched: (state, action) => {
      const { entity } = action.payload;
      state.dispObjtLoading = false;
      state.dispObjtEntity = entity;
      state.error = null;
    },

    dispatchingObjVarFetched: (state, action) => {
      const { entities } = action.payload;
      state.dispObjtLoading = false;
      state.dispObjVarEntities = entities;
      state.error = null;
    },
  },
});
