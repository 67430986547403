/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { get, merge } from 'lodash';
import { useIntl } from 'react-intl';

import { FormHelperText, Switch } from '@material-ui/core';
import clsx from 'clsx';
// https://github.com/conorhastings/react-syntax-highlighter#prism
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// See https://github.com/PrismJS/prism-themes
import { coy as highlightStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useHtmlClassService, setLayoutConfig, getInitLayoutConfig } from '../../layout';
import { Card, CardBody, CardHeader, Notice } from '../controls';

const localStorageActiveTabKey = 'builderActiveTab';

export function Builder() {
  const intl = useIntl();
  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [key, setKey] = useState(activeTab ? +activeTab : 0);
  const [isLoading, setIsLoading] = useState(false);
  const htmlClassService = useHtmlClassService();
  const initialValues = useMemo(
    () =>
      merge(
        // Fulfill changeable fields.
        getInitLayoutConfig(),
        htmlClassService.config,
      ),
    [htmlClassService.config],
  );

  const saveCurrentTab = _tab => {
    localStorage.setItem(localStorageActiveTabKey, _tab);
  };

  return (
    <>
      <Notice svg="/media/svg/icons/Tools/Compass.svg">
        <p className="m-0">{intl.formatMessage({ id: 'BUILDER.DESCRIPTION' })}</p>
      </Notice>
      {/*Formic off site: https://jaredpalmer.com/formik/docs/overview*/}
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          setIsLoading(true);
          setLayoutConfig(values);
        }}
        onReset={() => {
          setIsLoading(true);
          setLayoutConfig(getInitLayoutConfig());
        }}
      >
        {({ values, handleReset, handleSubmit, handleChange, handleBlur }) => (
          <>
            <div className="card card-custom">
              {/*Header*/}
              <div className="card-header card-header-tabs-line">
                <ul className="nav nav-dark nav-bold nav-tabs nav-tabs-line" data-remember-tab="tab_id" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 0 ? 'active' : ''}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(0);
                        saveCurrentTab(0);
                      }}
                    >
                      {intl.formatMessage({ id: 'BUILDER.HEADER.HEADER' })}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 1 ? 'active' : ''}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(1);
                        saveCurrentTab(1);
                      }}
                    >
                      {intl.formatMessage({ id: 'BUILDER.SUBHEADER.SUBHEADER' })}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 2 ? 'active' : ''}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(2);
                        saveCurrentTab(2);
                      }}
                    >
                      {intl.formatMessage({ id: 'BUILDER.CONTENT.CONTENT' })}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 3 ? 'active' : ''}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(3);
                        saveCurrentTab(3);
                      }}
                    >
                      {intl.formatMessage({ id: 'BUILDER.ASIDE.ASIDE' })}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${key === 4 ? 'active' : ''}`}
                      data-toggle="tab"
                      onClick={() => {
                        setKey(4);
                        saveCurrentTab(4);
                      }}
                    >
                      {intl.formatMessage({ id: 'BUILDER.FOOTER.FOOTER' })}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="form">
                <div className="card-body">
                  <div className="tab-content pt-3">
                    <div className={`tab-pane ${key === 0 ? 'active' : ''}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.HEADER.FIXED_HEADER' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <Switch
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="header.self.fixed.desktop"
                            checked={!!get(values, 'header.self.fixed.desktop')}
                          />
                          <FormHelperText>
                            {intl.formatMessage({ id: 'BUILDER.HEADER.FIXED_HEADER_HELP' })}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.HEADER.HEADER_WIDTH' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control form-control-solid"
                            name="header.self.width"
                            onBlur={handleBlur}
                            value={get(values, 'header.self.width')}
                            onChange={handleChange}
                          >
                            <option value="fluid">{intl.formatMessage({ id: 'BUILDER.FLUID' })}</option>
                            <option value="fixed">{intl.formatMessage({ id: 'BUILDER.FIXED' })}</option>
                          </select>
                          <FormHelperText>
                            {intl.formatMessage({ id: 'BUILDER.HEADER.HEADER_WIDTH_HELP' })}
                          </FormHelperText>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.HEADER.MENU_ARROWS' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <Switch
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="header.menu.self.root-arrow"
                            checked={!!get(values, 'header.menu.self.root-arrow')}
                          />
                          <FormHelperText>
                            {intl.formatMessage({ id: 'BUILDER.HEADER.MENU_ARROWS_HELP' })}
                          </FormHelperText>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 1 ? 'active' : ''}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.SUBHEADER.SUBHEADER' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <Switch
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="subheader.display"
                            checked={!!get(values, 'subheader.display')}
                          />
                          <FormHelperText>
                            {intl.formatMessage({ id: 'BUILDER.SUBHEADER.DISPLAY_SUBHEADER' })}
                          </FormHelperText>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.WIDTH' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="subheader.width"
                            onBlur={handleBlur}
                            value={get(values, 'subheader.width')}
                            onChange={handleChange}
                          >
                            <option value="fluid">{intl.formatMessage({ id: 'BUILDER.FLUID' })}</option>
                            <option value="fixed">{intl.formatMessage({ id: 'BUILDER.FIXED' })}</option>
                          </select>
                          <FormHelperText>{intl.formatMessage({ id: 'BUILDER.SELECT_WIDTH' })}</FormHelperText>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 2 ? 'active' : ''}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.WIDTH' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control"
                            name="content.width"
                            onBlur={handleBlur}
                            value={get(values, 'content.width')}
                            onChange={handleChange}
                          >
                            <option value="fluid">{intl.formatMessage({ id: 'BUILDER.FLUID' })}</option>
                            <option value="fixed">{intl.formatMessage({ id: 'BUILDER.FIXED' })}</option>
                          </select>
                          <FormHelperText>{intl.formatMessage({ id: 'BUILDER.SELECT_WIDTH' })}</FormHelperText>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 3 ? 'active' : ''}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.ASIDE.DISPLAY' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <Switch
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="aside.self.display"
                            checked={!!get(values, 'aside.self.display')}
                          />
                          <FormHelperText>{intl.formatMessage({ id: 'BUILDER.ASIDE.DISPLAY_ASIDE' })}</FormHelperText>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label pt-4 text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.ASIDE.SUBMENU' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <Switch
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="aside.menu.dropdown"
                            checked={!!get(values, 'aside.menu.dropdown')}
                          />
                          <FormHelperText>{intl.formatMessage({ id: 'BUILDER.ASIDE.SUBMENU_HELP' })}</FormHelperText>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane ${key === 4 ? 'active' : ''}`}>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.FOOTER.FOOTER_LAYOUT' })}:
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control form-control-solid"
                            name="footer.layout"
                            onBlur={handleBlur}
                            value={get(values, 'footer.layout')}
                            onChange={handleChange}
                          >
                            <option value="compact">
                              {intl.formatMessage({ id: 'BUILDER.FOOTER.FOOTER_LAYOUT_COMPACT' })}
                            </option>
                            <option value="extended">
                              {intl.formatMessage({ id: 'BUILDER.FOOTER.FOOTER_LAYOUT_EXTENDED' })}
                            </option>
                          </select>
                          <FormHelperText>
                            {intl.formatMessage({ id: 'BUILDER.FOOTER.FOOTER_LAYOUT_HELP' })}
                          </FormHelperText>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label text-lg-right">
                          {intl.formatMessage({ id: 'BUILDER.WIDTH' })}::
                        </label>
                        <div className="col-lg-9 col-xl-4">
                          <select
                            className="form-control form-control-solid"
                            name="footer.width"
                            onBlur={handleBlur}
                            value={get(values, 'footer.width')}
                            onChange={handleChange}
                          >
                            <option value="fluid">{intl.formatMessage({ id: 'BUILDER.FLUID' })}</option>
                            <option value="fixed">{intl.formatMessage({ id: 'BUILDER.FIXED' })}</option>
                          </select>
                          <FormHelperText>
                            {intl.formatMessage({ id: 'BUILDER.FOOTER.FOOTER_LAYOUT_HELP' })}
                          </FormHelperText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-9">
                      <button type="button" onClick={handleSubmit} className={`btn btn-primary font-weight-bold mr-2`}>
                        <i className="la la-eye" />
                        {intl.formatMessage({ id: 'BUILDER.SAVE_BTN' })}
                      </button>{' '}
                      <button type="button" onClick={handleReset} className={`btn btn-clean font-weight-bold mr-2`}>
                        <i className="la la-recycle" /> {intl.formatMessage({ id: 'BUILDER.RESET_BTN' })}
                      </button>{' '}
                      <span
                        className={`ml-3 ${clsx({
                          spinner: isLoading,
                        })}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Config*/}
            {/* <Card className="mt-4">
              <CardHeader
                title={
                  <>
                    Generated Config{' '}
                    <small>
                      can be used for layout config in <code>/src/_metronic/layout/LayoutConfig.js</code>
                    </small>
                  </>
                }
              />

              <CardBody>
                <SyntaxHighlighter
                  language="json"
                  style={highlightStyle}
                  customStyle={{
                    background: `none transparent !important`,
                  }}
                >
                  {JSON.stringify(values, null, 2)}
                </SyntaxHighlighter>
              </CardBody>
            </Card> */}
          </>
        )}
      </Formik>
    </>
  );
}
