import React, { useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { OrgSelectWidget } from '../../../widgets/ObjAndOrgSelectWidget/OrgSelectWidget';
import { ObjSelectWidget } from '../../../widgets/ObjAndOrgSelectWidget/ObjSelectWidget';

const ProjectCreateSchema =  Yup.object().shape({
});

export const CreateNewOrderDialog = ({ showModal, setShowModal, createOrder }) => {
  const [selectedObj, setSelectedObj] = useState('');
  const [selectedOrg, setSelectedOrg] = useState('');
  const formikRef = useRef(null);

  return (
    <>
      <Modal id="CreateNewOrderDialog" dialogClassName="" show={showModal} onHide={setShowModal} animation={true}>
        <Modal.Header>
          <Modal.Title>Новый заказ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            innerRef={formikRef}
            initialValues={{
              oid: { label: '',value: '' },
              object: { label: '',value: '' },
            }}
            validationSchema={ProjectCreateSchema}
            onSubmit={(values) => {
              createOrder({ oid: values.oid.value, object: values.object.value });
            }}
          >
            {({ errors, touched }) => (
              <Form className="form form-label-right">
                <div className="form-group row">
                  <label className="col-12 col-form-label text-alert">
                    Организация
                  </label>
                  <div className={`col-12 d-flex ${(errors.oid && touched.oid && "is-invalid") || ''}`}>
                    <OrgSelectWidget selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} formikRef={formikRef}/>
                  </div>
                  {errors.oid && <div className="invalid-feedback">{errors.oid}</div>}
                </div>
                <div className="form-group row">
                  <label className="col-12 col-form-label text-alert">
                    Объект
                  </label>
                  <div className={`col-12 d-flex ${(errors.object && touched.object && "is-invalid") || ''}`}>
                    <ObjSelectWidget selectedObj={selectedObj} setSelectedObj={setSelectedObj} formikRef={formikRef} />
                  </div>
                  {errors.object && <div className="invalid-feedback">{errors.object}</div>}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={()=>formikRef.current.handleSubmit()}
            variant="primary"
          >
            Создать
          </Button>
          <Button onClick={() => setShowModal(false)} variant="primary">
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};