/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ROUTES } from '../../../../app/Routes.models';

export function StickyToolbar() {
  return (
    <>
      <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
        <OverlayTrigger placement="left" overlay={<Tooltip id="layout-tooltip">Настройки</Tooltip>}>
          <li className="nav-item mb-2" data-placement="left">
            <Link
              to={ROUTES.USER_PROFILE_BUILDER}
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
            >
              <i className="flaticon2-gear"></i>
            </Link>
          </li>
        </OverlayTrigger>

        <OverlayTrigger placement="left" overlay={<Tooltip id="documentations-tooltip">Инструкции</Tooltip>}>
          <li className="nav-item mb-2" data-placement="left">
            <a
              className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
              target="_blank"
              rel="noopener noreferrer"
              href="http://wiki.atri-energo.ru/doku.php?id=lk"
            >
              <i className="flaticon2-telegram-logo"></i>
            </a>
          </li>
        </OverlayTrigger>
      </ul>
    </>
  );
}
