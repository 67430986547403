import React from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { MyObjectsMapContent } from '../myObjects-map/MyObjectsMapContent';

export const MyObjectMapDialog = ({ show, onHide, id }) => {
  const { currentState } = useSelector(state => ({ currentState: state.myObjects }), shallowEqual);
  const { entities } = currentState;

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      {entities && <MyObjectsMapContent onHide={onHide} entities={entities.find(obj => obj.id === Number(id))} />}
    </Modal>
  );
}