/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from '../../../_helpers';

export function TilesWidget12({
  className,
  iconColor = 'success',
  widgetHeight = '150px',
  counter = '8.600',
  text = 'New Customers',
  href = '',
}) {
  const history = useHistory();

  const redirect = useCallback(() => {
    history.push(href);
  }, [history, href]);

  return (
    <>
      <div className={`card card-custom ${className}`} style={{ height: widgetHeight }}>
        <div onClick={() => redirect()} className="card-body cursor-pointer">
          <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart1.svg')} />
          </span>
          <div className="text-dark font-weight-bolder font-size-h2 mt-3">{counter}</div>
          <a className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1">{text}</a>
        </div>
      </div>
    </>
  );
}
