import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSubheader } from '../../../../_metronic/layout';
import { useIntl } from 'react-intl';
import { ProfileCard } from './ProfileCard';
import { ChangePassword } from './changePassword/ChangePassword';
import { PersonaInformation } from './personalInformation/PersonaInformation';
import { BuilderPage } from '../../../pages/BuilderPage';
import { ROUTES } from '../../../Routes.models';

export default function UserProfilePage() {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: 'USERPROFILE' }));

  return (
    <div className="d-flex flex-row">
      <ProfileCard />
      <div className="flex-row-fluid ml-lg-8">
        <Switch>
          <Route path={ROUTES.USER_PROFILE_INFO} component={PersonaInformation} />
          <Route path={ROUTES.USER_PROFILE_PASS} component={ChangePassword} />
          <Route path={ROUTES.USER_PROFILE_BUILDER} component={BuilderPage} />
          <Redirect exact from={ROUTES.USER_PROFILE} to={ROUTES.USER_PROFILE_INFO} />
        </Switch>
      </div>
    </div>
  );
}
