import React, { useMemo } from "react";
import { Formik } from "formik";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";
import { useNotificationsUIContext } from "../NotificationsUIContext";

const prepareFilter = (queryParams, values) => {
  const { module } = values;
  const newQueryParams = { ...queryParams };
  const filter = {
    module: {
      value: '',
      operator: '',
    },
  };

  // Filter by status
  if (module !== "") {
    filter.module.operator = "=";
    filter.module.value = module;
  } else {
    delete filter.module;
  };

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function NotificationsFilter({ listLoading }) {
  const intl = useIntl();
  const notificationsUIContext = useNotificationsUIContext();
  const notificationsUIProps = useMemo(() => {
    return {
      queryParams: notificationsUIContext.queryParams,
      setQueryParams: notificationsUIContext.setQueryParams,
    };
  }, [notificationsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(notificationsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, notificationsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      notificationsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          module: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="module"
                  placeholder="Filter by Module"
                  onChange={(e) => {
                    setFieldValue("module", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.module}
                >
                  <option value="">Все</option>
                  <option value="task">Задачи</option>
                  <option value="sales">Продажи</option>
                  <option value="chat">Чат</option>
                </select>
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: "TABLE.FILTER" })}</b> {intl.formatMessage({ id: "TABLE.FILTER.BY_STATUS" })}
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
