import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import * as action from './../_redux/authActions'

const initialValues = {
  code: '',
};

export const CheckCode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const { currentState } = useSelector(state => ({ currentState: state.auth }));
  const{ checkCodeType, checkCodeLogin } = currentState;

  useEffect(()=>{
    if (!checkCodeType) {
      history.push("/auth/registration");
    }
  }, [history, checkCodeType]);

  const LoginSchema = Yup.object().shape({
    code: Yup.number()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setFieldValue, setStatus, setSubmitting } ) => {
      setSubmitting(true);
      enableLoading();
      dispatch(action.registerByCode(values))
        .catch((e) => {
          disableLoading();
          setSubmitting(false);
          setFieldValue('code', '');
          setStatus(e.message);
        })
    },
  });

  const backBtnHandler = useCallback(() => {
    dispatch(action.clearCheckCodeState());
    history.push("/auth/registration");
  }, [dispatch, history]);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-left">
        <h3 className="font-size-h1">
          {checkCodeType === 'email' ? 'Введите E-mail код' :  'Введите SMS код'}
        </h3>
        <p className="text-muted font-weight-bold font-size-h6">
          {checkCodeType === 'email' ? 'Почта получателя: ' : 'Номер получателя: '
          } {checkCodeLogin}
        </p>
      </div>
      <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}
        <div className="form-group fv-plugins-icon-container">
          <label className="col-form-label text-center">
            <p className="font-weight-bold font-size-h6">
              {checkCodeType === 'email' ? 'Код из e-mail' : 'Код из SMS'}
            </p>
          </label>
          <input
            placeholder="Code"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('code')}`}
            autoComplete="off"
            name="code"
            {...formik.getFieldProps('code')}
          />
          {formik.touched.code && formik.errors.code ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.code}</div>
            </div>
          ) : null}
        </div>
        <div className="text-left mb-10 mb-lg-20">
          <p className="font-weight-bold font-size-h8">Отправить повторно, через 57 сек.</p>
        </div>

        <div className="form-group d-flex flex-wrap align-items-center flex-center">
          <button
            type="button"
            onClick={() => backBtnHandler()}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            Назад
          </button>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Продолжить</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
};