/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Layout, LayoutSplashScreen } from '../_metronic/layout';
import BasePage from './BasePage';
import { AuthPage } from './modules/Auth/pages/AuthPage';
import { Logout } from './modules/Auth/pages/Logout';
import * as actions from './modules/Auth/_redux/authActions';
import { ROUTES } from './Routes.models';
import { ErrorPage4 } from './modules/Errors/ErrorPage4';
import { YMInitializer } from 'react-yandex-metrika';

export function Routes() {
  const dispatch = useDispatch();
  const { authState } = useSelector(state => ({ authState: state.auth }), shallowEqual);
  const { authToken, isAuthorized, ymetrika } = authState;

  useEffect(() => {
    if (authToken) {
      dispatch(actions.fetchUser());
    }
  }, [authToken, dispatch]);

  return (
    <>
      {ymetrika && (
        <YMInitializer
          accounts={[83245114]}
          options={{ webvisor: true, clickmap: true, trackLinks: true, accurateTrackBounce: true }}
          version="2"
        />
      )}
      <Switch>
        <Route path={ROUTES.ERROR} component={ErrorPage4} />

        {authToken && !isAuthorized && <LayoutSplashScreen />}
        {!isAuthorized ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <Redirect from={ROUTES.AUTH} to="/" />
        )}

        <Route path={ROUTES.LOGOUT} component={Logout} />

        {!isAuthorized ? (
          <Redirect to={ROUTES.AUTH_REG} />
        ) : (
          <Layout>
            <BasePage />
          </Layout>
        )}
      </Switch>
    </>
  );
}
