import { createSlice } from '@reduxjs/toolkit';

const initialPumpStationState = {
  pumpStationSending: false,
};

export const callTypes = {
  stationSend: 'stationSend',
};

export const calculationPumpStationSlice = createSlice({
  name: 'calculationPumpStation',
  initialState: initialPumpStationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.stationSend) {
        state.pumpStationSending = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;

      if (action.payload.callType === callTypes.stationSend) {
        state.pumpStationSending = true;
        return;
      }
    },

    pumpStationFetched: (state, action) => {
      state.pumpStationSending = false;
      state.error = null;
    },
  },
});
