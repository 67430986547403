import * as requestFromServer from './unreadNotifiCrud';
import { unreadNotifiSlice, callTypes } from './unreadNotifiSlice';

const { actions } = unreadNotifiSlice;

export const fetchUnreadNotifi = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findUnreadNotifi(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.unreadNotifiFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find unreadNotifi!";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
