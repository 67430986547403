import React from 'react';
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import { stepIcons, stepName } from '../ScannerUIHelpers';

export const HeadSteps = ({ step }) => {
  return (
    <div className="wizard wizard-1 w-100" id="kt_wizard">
      <div className="wizard-nav border-bottom">
        <div className="wizard-steps p-5 p-lg-6">

          {stepName.map((item, index) => {
            return (
              <div key={index} className="wizard-step" data-wizard-type="step" data-wizard-state={index <= step ? "current" : "pending"}>
                <div className="wizard-label">
                  <i className={`wizard-icon font-size-h1 ${stepIcons[index]}`}></i>
                  <h3 className="wizard-title">{`${index + 1}. ${item}`}</h3>
                </div>
                <span className={`svg-icon svg-icon-xl wizard-arrow ${index === stepName.length - 1 ? 'last' : '' }`}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
                  ></SVG>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};