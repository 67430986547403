export const stepName = [
  'Начало',
  'Загрузка документа',
  'Выбор страниц',
  'Выбор товаров',
  'Завершение',
];

export const stepIcons = [
  "flaticon-bus-stop",
  "flaticon-list",
  "flaticon-responsive",
  "flaticon-truck",
  "flaticon-globe",
];

export const THIRD_STEP_TABLE_CLASSES = {
  pos: 'w-90px',
  title: 'w-300px',
  marka: 'w-300px',
  sku: 'w-300px',
  man: 'w-300px',
  unit: 'w-300px',
};

export const initialFormsData = {
  step1: {
    docType: '0',
    section: '0',
    order: { value: 0, label: 'Новый заказ' },
    oid: { label: '',value: '' },
    object: { label: '',value: '' },
  },
  step3: {
    uniqid: null,
    checkedPage: [],
    pos: 0,
    title: 2,
    marka: 3,
    sku: 4,
    man: 5,
    unit: 6,
    count: 7,
  },
  step4: {
    checkedPoss: [],
  }
};