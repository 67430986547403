/* eslint-disable no-undef */
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import store from '../../../../../redux/store';

import * as actions from '../../redux/scannerr/scannerActions';
import { initialFormsData } from './ScannerUIHelpers';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const ScannerUIContext = createContext();

export function useScannerUIContext() {
  return useContext(ScannerUIContext);
}

export const ScannerUIConsumer = ScannerUIContext.Consumer;

export const ScannerUIProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [scanFile, setScanFile] = useState({ file: null });
  const [formDataStep1, setFormDataStep1] = useState({ ...initialFormsData.step1 });
  const [formDataStep3, setFormDataStep3] = useState({ ...initialFormsData.step3 });
  const [formDataStep4, setFormDataStep4] = useState({ ...initialFormsData.step4 });

  const victoriaRun = useCallback(
    (row, parentRow) => {
      const token = store.getState().auth.authToken;

      $Victoria.changeItem = async function (id, newCid) {
        dispatch(actions.fetchGetProductByCID({ cid: newCid, pos: row.pos })).then(product => {
          dispatch(actions.changeScanList({ product: product }));
        });
      };

      $Victoria.link = window.open(
        `${ENDPOINT}victoria?action=view_min&id=0&search_value=${parentRow.title}&token=${token}`,
        '1600667945098',
        'width=1400,height=840,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0',
      );
    },
    [dispatch],
  );

  const value = {
    scanFile,
    setScanFile,
    formDataStep3,
    setFormDataStep3,
    victoriaRun,
    formDataStep1,
    setFormDataStep1,
    formDataStep4,
    setFormDataStep4,
  };

  return <ScannerUIContext.Provider value={value}>{children}</ScannerUIContext.Provider>;
};
