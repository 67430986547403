import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';

import { ObjSelectWidget } from '../../../../../widgets/ObjAndOrgSelectWidget/ObjSelectWidget';
import { OrgSelectWidget } from '../../../../../widgets/ObjAndOrgSelectWidget/OrgSelectWidget';
import { useScannerUIContext } from '../ScannerUIContext';
import { initialFormsData } from '../ScannerUIHelpers';

const Schema =  Yup.object().shape({
});

export const Step1 = () => {
  const [selectedOrd, setSelectedOrd] = useState({ value: 0, label: 'Новый заказ' });
  const [ordVal, setOrdVal] = useState([]);
  const [selectedObj, setSelectedObj] = useState('');
  const [selectedOrg, setSelectedOrg] = useState('');

  const formikRef = useRef(null);

  const scannerUIContext = useScannerUIContext();
  const scannerUIProps = useMemo(() => {
    return {
      formDataStep1: scannerUIContext.formDataStep1,
      setFormDataStep1: scannerUIContext.setFormDataStep1,
    };
  }, [scannerUIContext.formDataStep1, scannerUIContext.setFormDataStep1]);

  const { scannerState } = useSelector(state => ({ scannerState: state.scanner }));
  const { ordersEntities } = scannerState;

  useEffect(() => {
    if (ordersEntities.length) {
      setOrdVal([{ ...initialFormsData.step1.order }, ...ordersEntities.map(order => ({ value: order.id, label: order.numb })) ]);
    };
  }, [ordersEntities]);

  const handleChangeAnyField = target => {
    scannerUIProps.setFormDataStep1({ ...scannerUIProps.formDataStep1, [target.name]: target.value });
  };

  const handleObjectChange = val => {
    setSelectedOrd(val);
    handleChangeAnyField({ name: 'order', value: val });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        docType: initialFormsData.step1.docType,
        section: initialFormsData.step1.section,
        order: initialFormsData.step1.order,
        oid: initialFormsData.step1.oid,
        object: initialFormsData.step1.object,
      }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values);

        //saveNewBTPProject(values, setSubmitting);
      }}
    >
      {({ isSubmitting, errors, touched, isValid, values, setFieldValue }) => (
        <Form className="form form-label-right">
          <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <h3 className="mb-10 font-weight-bold text-dark">Сканер документов</h3>
            <p className="font-weight-bolder mb-3">
              Модуль сканер документов помогает пользователям быстро и удобно обрабатывать печатную информацию и импортировать её в заказы или создавать электронные версии документов.
            </p>
            <div className="separator separator-dashed my-8" />
            <div className="row">
              <div className="col-6">
                <h6 className="font-weight-bolder mb-7">
                  Укажите тип исходного документа:
                </h6>
                <div className="form-group">
                  <label>Выберите тип документа</label>
                  <Field
                    as="select"
                    name="docType"
                    className="form-control form-control-solid form-control-lg"
                    onChange={(e)=>handleChangeAnyField(e.target)}
                  >
                    <option value="0">Спецификация</option>
                    <option disabled value="1">Калькуляция</option>
                  </Field>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <h6 className="font-weight-bolder mb-7">
                      Укажите раздел в который будет загружена информация из документа:
                    </h6>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6">
                          <label>Выберите раздел</label>
                          <Field
                            as="select"
                            name="section"
                            className="form-control form-control-solid form-control-lg"
                            onChange={(e)=>handleChangeAnyField(e.target)}
                          >
                            <option value="0">Заказы</option>
                            <option disabled value="1">Спецификация</option>
                          </Field>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Выберите заказ</label>
                            <div className="d-flex">
                              <Select
                                placeholder="Выберите заказ"
                                value={selectedOrd}
                                name="order"
                                onChange={handleObjectChange}
                                classNamePrefix="react-select"
                                className={'react-select'}
                                options={ordVal}
                              />
                            </div>
                          </div>
                          <div className={`${selectedOrd.value !== 0 ? 'opacity-50' : ''}`} />
                        </div>
                        <div className="col-12">
                          <span className="form-text text-muted">
                            Укажите заказ в который будут дабавлены товары, если создаете новый заказ - выберите соответствующий пункт.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group row">
                      <label className="col-12 col-form-label text-alert">
                        Организация
                      </label>
                      <div className="col-12 d-flex">
                        <OrgSelectWidget selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} formikRef={formikRef} isDisabled={selectedOrd.value !== 0} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-12 col-form-label text-alert">
                        Объект
                      </label>
                      <div className={`col-12 d-flex`}>
                        <ObjSelectWidget selectedObj={selectedObj} setSelectedObj={setSelectedObj} formikRef={formikRef} isDisabled={selectedOrd.value !== 0} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};