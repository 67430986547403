import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import * as actions from '../../../_redux/myOrganisations/myOrganisationsActions';
import { daDataServices } from '../../../../../services/daDataServices';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModalInfo } from '../../../../../widgets/modalInfo/ModalInfo';
import { initialFilter } from '../MyOrganisationsUIHelpers';

const OrganisationCreateSchema = Yup.object().shape({
  inn: Yup.string()
    .required('Поле не должно быть пустым'),
});

export const OrganisationCreateDialog = ({ showModal, setShowModal }) => {
  const [showInputHelp, setisShowInputHelp] = useState(false);
  const [formInput, setFormInput] = useState('');
  const [InputHelp, setInputHelp] = useState([]);
  const [lastClickVal, setLastClickVal] = useState('');
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoText, setModalInfoText] = useState('');
  const [modalInfoTitle, setModalInfoTitle] = useState('');

  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.myOrganisations }),
    shallowEqual
  );

  const { modalData, orgSaving } = currentState;

  useEffect(() => {
    if (!modalData) {
      return;
    };

    setModalInfoText(modalData.text);
    setModalInfoTitle(modalData.title);
    setShowModalInfo(true);
    setShowModal(false);
  }, [modalData, setShowModal]);

  const saveObject = useCallback(values => {
    const inn = values.inn.slice((values.inn.lastIndexOf('(') + 1), -1);
    const sendingValues = { ...values,  inn: inn };
    dispatch(actions.fetchSaveMyOrganisations(sendingValues))
      .then(() => dispatch(actions.fetchMyOrganisations(initialFilter)));
  }, [dispatch]);

  const setInput = useCallback(
    e => {
      setFormInput(e.target.value);
      formikRef.current.setFieldValue('inn', e.target.value);
      if (e.target.value === '') {
        setisShowInputHelp(false);
        formikRef.current.setFieldValue('inn', e.target.value);
      }
    }, [],
  );

  const InputHelpClick = useCallback(
    e => {
      formikRef.current.setFieldValue('inn', e.target.innerHTML);
      setLastClickVal(e.target.innerHTML);
      setFormInput(e.target.innerHTML);
      setInputHelp([]);
      setisShowInputHelp(false);
    },
    [],
  );

  useEffect(() => {
    if (lastClickVal === formInput || formInput === '') {
      setisShowInputHelp(false);
      return;
    }

    const getHelp = async () => {
      const { suggestions } = await daDataServices.getOrganizationDescription({ userQuery: formInput });
      const helpVariants = suggestions.map(variant => `${variant['value']} (${variant.data.inn})`);
      setInputHelp(helpVariants);
    };

    if (formInput.length !== 0 && lastClickVal !== formInput) {
      getHelp();
      setisShowInputHelp(true);
    }
  }, [formInput, lastClickVal]);

  const modalInfoOnOff = useCallback((trigger) => {
    if (!trigger) {
      setShowModalInfo(false);
      dispatch(actions.fetchClearSaveResponse());
      setFormInput('');
    }
  }, [dispatch]);

  return (
    <>
      <ModalInfo showModal={showModalInfo} setShowModal={modalInfoOnOff} title={modalInfoTitle} text={modalInfoText} />
      <Modal dialogClassName="" show={showModal} onHide={setShowModal} animation={true}>
        <Modal.Header>
          <Modal.Title>Новая организация</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            innerRef={formikRef}
            initialValues={{
              inn: '',
              post: '',
            }}
            validationSchema={OrganisationCreateSchema}
            onSubmit={values => {
              saveObject(values);
            }}
          >
            {({ isSubmitting, errors, touched, isValid }) => (
              <Form className="form form-label-right">
                <div className="form-group row">
                  <label className="col-12 col-form-label text-alert">Выберите организацию:</label>
                  <div className="col-12">
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Введите ИНН или название"
                        className={`form-control form-control-lg form-control-solid ${touched.inn && errors.inn && "is-invalid"}`}
                        name="inn"
                        onChange={e => setInput(e)}
                        autoComplete="off"
                        value={formInput || ''}
                        disabled={isSubmitting}
                      />
                      {(errors.inn && touched.inn) && <div className="invalid-feedback">{errors.inn}</div>}
                      <select
                        className={`custom-select position-absolute z-10 ${showInputHelp ? 'd-block' : 'd-none'}`}
                        size={InputHelp.length <= 1 ? InputHelp.length + 1 : InputHelp.length - 1}
                      >
                        {InputHelp.map(helpItem => (
                          <option key={helpItem} onClick={e => InputHelpClick(e)}>
                            {helpItem}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-12 col-form-label text-alert">Ваша должность</label>
                  <div className="col-12">
                    <Field
                      name="post"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Ваша должность"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowModal(false)} variant="primary">
            Закрыть
          </button>
          <button
            className="btn btn-primary"
            onClick={() => formikRef.current.handleSubmit()}
            disabled={orgSaving}
          >
            Создать организацию
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
