import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/min/moment-with-locales';

import { Card, CardBody, CardFooter, CardHeader } from '../../../_metronic/_partials/controls';
import { CardSpinner } from '../../widgets/cardSpinner/CardSpinner';
import * as actions from './redux/promotionActions';
import { PromotionTable } from './PromotionTable';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../Routes.models';
import { ModalInfo } from '../../widgets/modalInfo/ModalInfo';
import { CreateNewOrderDialog } from './components/CreateNewOrderDialog';

export const PromotionCard = ({ id }) => {
  const [tableSelectIds, setTableSelectIds] = useState([]);
  const [showModalResult, setShowModalResult] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [resultId, setResultId] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const { currentState } = useSelector(state => ({ currentState: state.promotion }));
  const { promotionLoading, promotionEntities, promotion, promoOrderLoading } = currentState;

  useEffect(() => {
    dispatch(actions.fetchPromotion({ id: id }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSelect = useCallback((row, isSelect) => {
    if (isSelect) {
      setTableSelectIds([...tableSelectIds, row.id]);
      return;
    };

    const ids = [...tableSelectIds];
    const index = ids.indexOf(row.id);
    if (index !== -1) {
      ids.splice(index, 1);
      setTableSelectIds([...ids]);
    }
  }, [tableSelectIds]);

  const createOrder = useCallback((values) => {
    dispatch(actions.fetchCreatePromoOrder({ ...values, ids: tableSelectIds }))
      .then(({ type, sales_id }) => {
        if (type === 'success') {
          setResultId(sales_id);
        };

        setShowModalResult(true);
      });

  }, [dispatch, tableSelectIds]);

  const goToOrderPage = useCallback(() => {
    history.push(`${ROUTES.ORDERS}/${resultId}/order`);
  }, [history, resultId]);

  return (
    <>
      {(promotionLoading || promoOrderLoading) && <CardSpinner />}
      {(showCreateDialog && !showModalResult) && <CreateNewOrderDialog showModal={showCreateDialog} setShowModal={setShowCreateDialog} createOrder={createOrder} />}
      <ModalInfo
        showModal={showModalResult}
        setShowModal={()=>{setShowModalResult(false)}}
        text="Заказ успешно создан."
        leftBtnText="Перейти на страницу заказа"
        leftBtnAction={goToOrderPage}
        rightBtnText="Закрыть"
        rightBtnAction={()=>{setShowModalResult(false)}}
      />
      {promotion && <div className="row">
        <div className="col-12">
          <Card className="card-stretch">
            <CardHeader title="Акция"/>
            <CardBody className="">
              <div className="row mb-7">
                <div className="col-3">
                  <img className="w-100" src={promotion.preview.source} alt="promo" />
                </div>
                <div className="col-9">
                  <h4 className="mb-4">
                    {promotion.title}
                  </h4>
                  <p className="mb-7" dangerouslySetInnerHTML={{ __html: promotion.full }} />
                  <h4 className="text-dark-75 font-weight-bolder mb-7">
                    {`Акция действует с ${moment(promotion.date_start).format('DD.MM.YYYY')} по ${moment(promotion.date_end).format('DD.MM.YYYY')}`}
                  </h4>
                </div>
              </div>
              <div className="row mb-7">
                <div className="col-12">
                  <PromotionTable promotionEntities={promotionEntities} handleOnSelect={handleOnSelect} />
                </div>
              </div>
            </CardBody>
            <CardFooter className="text-right">
              <button
                className="btn btn-primary"
                disabled={!tableSelectIds.length}
                onClick={()=>setShowCreateDialog(true)}
              >
                Создать заказ
              </button>
            </CardFooter>
          </Card>
        </div>
      </div>}
    </>
  );
};