import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const SUPPORT_SHOW = `${ENDPOINT}api/app/support.show?`;
const SUPPORT_GET = `${ENDPOINT}api/app/support.index?`;
const SUPPORT_STORE = `${ENDPOINT}api/app/support.store?`;
const SUPPORT_SHOW_SUP = `${ENDPOINT}api/app/support.show_support?`;
const SUPPORT_SHOW_MSGS = `${ENDPOINT}api/app/support.show_messages?`;
const SUPPORT_CREATE_MSGS = `${ENDPOINT}api/app/SupportMessage.store?`;
const SUPPORT_CREATE_FILE = `${ENDPOINT}api/app/SupportMessage.store_file?`;

export const createFileReports = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  const response = fetch(`${SUPPORT_CREATE_FILE}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());

  return await response;
};

export const createMsgsReports = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  const response = fetch(`${SUPPORT_CREATE_MSGS}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());

  return await response;
};

export const getReport = async queryParams => {
  const token = store.getState().auth.authToken;
  const response = fetch(`${SUPPORT_GET}token=${token}`).then(result => result.json());

  return await response;
};

export const showReports = async () => {
  const token = store.getState().auth.authToken;
  const response = fetch(`${SUPPORT_SHOW}token=${token}`).then(result => result.json());

  return await response;
};

export const createReport = queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  const response = fetch(`${SUPPORT_STORE}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());

  return response;
};

export const showSupReports = async () => {
  const token = store.getState().auth.authToken;
  const response = fetch(`${SUPPORT_SHOW_SUP}token=${token}`).then(result => result.json());

  return await response;
};

export const showSupMsgsReports = async queryParams => {
  //вывод потрахов
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  const response = fetch(`${SUPPORT_SHOW_MSGS}token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(result => result.json());

  return await response;
};
