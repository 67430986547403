import * as requestFromServer from './authCrud';
import { authSlice, callTypes } from './authSlice';

const { actions } = authSlice;

export const disableStartInfoTooltip = () => dispatch => {
  return dispatch(actions.disableStartInfoTooltip());
};

export const fetchToken = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getToken }));
  return requestFromServer
    .getToken(queryParams)
    .then(response => {
      if (!response.access_token) {
        throw new Error(response.text);
      }
      dispatch(actions.tokenFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't fetchToken!";
      dispatch(actions.catchError({ error, callType: callTypes.getToken }));
      throw new Error(error.message);
    });
};

export const fetchUser = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getUser }));
  return requestFromServer
    .getUserByToken()
    .then(response => {
      dispatch(actions.userFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't get user!";
      dispatch(actions.catchError({ error, callType: callTypes.getUser }));
    });
};

export const logout = () => dispatch => {
  return dispatch(actions.logoutUser());
};

export const fetchCheckCode = queryParameters => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getCode }));
  return requestFromServer
    .requestCheckCode(queryParameters)
    .then(response => {
      dispatch(actions.CheckCodeFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't check code!";
      dispatch(actions.catchError({ error, callType: callTypes.getCode }));
    });
};

export const setUser = user => dispatch => {
  return dispatch(actions.setUserData(user));
};

export const clearCheckCodeState = () => dispatch => {
  return dispatch(actions.clearCheckCode());
};

export const sendUserCode = queryParameters => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.sendCode }));
  return requestFromServer.sendCode(queryParameters).then(response => {
    if (response.type === 'error') {
      throw new Error(response.error);
    }
    dispatch(actions.codeSended(response));
  });
};

export const registerByCode = queryParameters => dispatch => {
  return dispatch(sendUserCode(queryParameters))
    .then(() => dispatch(fetchUser()))
    .catch(error => {
      error.clientMessage = "Can't register by code!";
      throw new Error(error.message);
    });
};
