import React, { useMemo } from 'react';
import { useCallback } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useScannerUIContext } from '../../../ScannerUIContext';
import { THIRD_STEP_TABLE_CLASSES } from '../../../ScannerUIHelpers';
import { InnerTable } from './innerTable/InnerTable';

export const Step4Table = () => {

  const { currentState } = useSelector(state => ({ currentState: state.scanner }));
  const { decodedScanList } = currentState;

  const scannerUIContext = useScannerUIContext();
  const scannerUIProps = useMemo(() => {
    return {
      formDataStep4: scannerUIContext.formDataStep4,
      setFormDataStep4: scannerUIContext.setFormDataStep4,
    };
  }, [scannerUIContext.formDataStep4, scannerUIContext.setFormDataStep4]);

  const columns = [
    {
      dataField: 'pos',
      text: '#',
      align: 'center',
      headerAlign: 'center',
      get hidden() {
        return decodedScanList[this.dataField] === undefined ? false : true;
      },
    }, {
      dataField: 'title',
      text: 'Тип, марка, обозначение',
      classes: function() {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      get hidden() {
        return decodedScanList[this.dataField] === undefined ? false : true;
      },
    }, {
      dataField: 'marka',
      text: 'Наименование и техническая характеристика',
      align: 'center',
      headerAlign: 'center',
      classes: function() {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      get hidden() {
        return decodedScanList[this.dataField] === undefined ? false : true;
      },
    }, {
      dataField: 'sku',
      text: 'Код оборудования, изделия, материала',
      align: 'center',
      headerAlign: 'center',
      classes: function() {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      get hidden() {
        return decodedScanList[this.dataField] === undefined ? false : true;
      },
    }, {
      dataField: 'unit',
      text: 'Единица измерения',
      align: 'center',
      headerAlign: 'center',
      classes: function() {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      get hidden() {
        return decodedScanList[this.dataField] === undefined ? false : true;
      },
    }, {
      dataField: 'count',
      text: 'Количество',
      align: 'center',
      headerAlign: 'center',
      classes: function() {return THIRD_STEP_TABLE_CLASSES[this.dataField]},
      get hidden() {
        return decodedScanList[this.dataField] === undefined ? false : true;
      }
    }
  ];

  const handleOnSelect = useCallback((row, isSelect) => {
    if (isSelect) {
      scannerUIProps.setFormDataStep4({ ...scannerUIProps.formDataStep4, checkedPoss: [...scannerUIProps.formDataStep4.checkedPoss, row.pos] });
      return;
    };

    const index = scannerUIProps.formDataStep4.checkedPoss.indexOf(row.pos);

    if (index !== -1) {
      const poss = [...scannerUIProps.formDataStep4.checkedPoss];
      poss.splice(index, 1);
      scannerUIProps.setFormDataStep4({ ...scannerUIProps.formDataStep4, checkedPoss: [...poss] });
    };

  }, [scannerUIProps]);

  const expandRow = {
    showExpandColumn: true,
    renderer: row => <InnerTable row={row}/>
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    clickToExpand: true,
    hideSelectAll: true,
    onSelect: handleOnSelect,
  };

  return (
    <PerfectScrollbar
      options={{
        suppressScrollX: "false"
      }}
      style={{ maxHeight: '550px' }}
      className="pr-4"
    >
      <div className="scannerThirdStepTable">
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={true}
          classes="table table-head-custom table-head-bg table-vertical-center overflow-hidden"
          bootstrap4
          remote
          keyField="pos"
          data={decodedScanList}
          columns={columns}
          selectRow={selectRow}
          expandRow={expandRow}
        />
      </div>
    </PerfectScrollbar>
  );
};