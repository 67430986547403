import store from '../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const ORGANISATIONS_URL = `${ENDPOINT}api/app/organization.get?`;
const ORGANISATIONS_CREATE_URL = `${ENDPOINT}api/app/Organization.store?`;

export async function findMyOrganisations(queryParams) {
  const token = store.getState().auth.authToken;
  const filter = JSON.stringify(queryParams.filter);

  return fetch(
    `${ORGANISATIONS_URL}search=${queryParams.searchText}&filter=${filter}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}&token=${token}`,
  ).then(res => res.json());
}

export async function getAllMyOrganisations() {
  const token = store.getState().auth.authToken;
  return fetch(`${ORGANISATIONS_URL}&token=${token}`).then(res => res.json());
}

export const saveMyOrganisations = async queryParams => {
  const token = store.getState().auth.authToken;
  return fetch(`${ORGANISATIONS_CREATE_URL}inn=${queryParams.inn}&post=${queryParams.post}&token=${token}`).then(res =>
    res.json(),
  );
};
