import React, { useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import * as orgActions from "../../modules/UserInfo/_redux/myOrganisations/myOrganisationsActions";
import { toAbsoluteUrl } from '../../../_metronic/_helpers/AssetsHelpers';
import { OrganisationCreateDialog } from '../../modules/UserInfo/pages/myOrganisations/organisationCreateDialog/OrganisationCreateDialog';

export const OrgSelectWidget = ({ selectedOrg, setSelectedOrg, formikRef, name='oid', isDisabled=false }) => {
  const [orgVal, setOrgVal] = useState([]);
  const [showModalOrgCreate, setShowModalOrgCreate] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(orgActions.getAllMyOrganisations());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { orgState } = useSelector((state) => ({ orgState: state.myOrganisations }), shallowEqual );

  const { lastCreatedOrgId, allEntities } = orgState;

  useEffect(() => {
    if (allEntities.length) {
      setOrgVal(allEntities.map(org => ({ value: org.id, label: org.name })));
    };
  }, [allEntities]);

  useEffect(() => {
    if (lastCreatedOrgId) {
      const createdOrg = allEntities.find(org => org.id === lastCreatedOrgId);
      createdOrg && setSelectedOrg({ value: createdOrg.id, label: createdOrg.name });
      formikRef.current.setFieldValue(name, createdOrg.id);
      dispatch(orgActions.clearLastCreatedOrgId());
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEntities]);

  const handleOrgChange = useCallback(val => {
    formikRef && formikRef.current.setFieldValue(name, String(val.value));
    setSelectedOrg(val);
  }, [formikRef, name, setSelectedOrg]);

  return (
    <>
      <Select
        value={selectedOrg}
        placeholder="Выберите организацию"
        name={name}
        onChange={handleOrgChange}
        classNamePrefix="react-select"
        className={'react-select'}
        options={orgVal}
        isDisabled={isDisabled}
      />
      <button
        type="button"
        className="btn btn-icon btn-light btn-hover-primary btn-lg ml-2"
        onClick={() => setShowModalOrgCreate(true)}
        disabled={isDisabled}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
        </span>
      </button>
      <OrganisationCreateDialog showModal={showModalOrgCreate} setShowModal={setShowModalOrgCreate} />
    </>
  );
};