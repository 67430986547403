import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader } from '../../../../../_metronic/_partials/controls';
import { DispatchingTable } from './components/dispatchingTable/DispatchingTable';
import * as orgActions from "./../../../UserInfo/_redux/myOrganisations/myOrganisationsActions";
import * as objActions from "./../../../UserInfo/_redux/myObjects/myObjectsActions";
import { DispatchingFilter } from './components/dispatchingTable/DispatchingFilter';

export const DispatchingCard = () => {
  const dispatch = useDispatch();

  const { currentState } = useSelector(state => ({ currentState: state.dispatching }));
  const { dispObjtsEntities, dispObjtsTotalCount, dispObjtsLoading } =  currentState;

  useEffect(() => {
    dispatch(objActions.getAllMyObjects());
    dispatch(orgActions.getAllMyOrganisations());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader title="Диспетчеризация" />
      <CardBody>
        <DispatchingFilter />
        <DispatchingTable entities={dispObjtsEntities} totalCount={dispObjtsTotalCount} loading={dispObjtsLoading} />
      </CardBody>
      <CardFooter />
    </Card>
  );
};