import { createSlice } from '@reduxjs/toolkit';

const initialReportState = {
  reportLoading: false,
  reportMsgsLoading: false,
  reportMsgCreating: false,
  reportCreating: false,
  reportFileCreating: false,
  entitiesReports: [],
  entitiesMsgs: [],
};

export const callTypes = {
  report: 'report',
  msgs: 'msgs',
  createMsgs: 'createMsgs',
  createReport: 'createReport',
  createFile: 'createFile',
};

export const reportsSlice = createSlice({
  name: 'report',
  initialState: initialReportState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.report) {
        state.reportLoading = false;
      }

      if (action.payload.callType === callTypes.msgs) {
        state.reportMsgsLoading = false;
      }

      if (action.payload.callType === callTypes.createMsgs) {
        state.reportMsgCreating = false;
      }

      if (action.payload.callType === callTypes.createReport) {
        state.reportCreating = false;
      }

      if (action.payload.callType === callTypes.createFile) {
        state.createFile = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.report) {
        state.reportLoading = true;
      }

      if (action.payload.callType === callTypes.msgs) {
        state.reportMsgsLoading = true;
      }

      if (action.payload.callType === callTypes.createMsgs) {
        state.reportMsgCreating = true;
      }

      if (action.payload.callType === callTypes.createReport) {
        state.reportCreating = true;
      }

      if (action.payload.callType === callTypes.createFile) {
        state.createFile = true;
      }
    },

    reportFetched: (state, action) => {
      const { entities } = action.payload;
      state.reportLoading = false;
      state.error = null;
      state.entitiesReports = entities;
    },

    msgsFetched: (state, action) => {
      const { messages } = action.payload;
      state.reportMsgsLoading = false;
      state.error = null;
      state.entitiesMsgs = messages;
    },

    msgCreateFetched: state => {
      state.reportMsgCreating = false;
      state.error = null;
    },

    createReportFetched: state => {
      state.reportCreating = false;
      state.error = null;
    },

    clearReportMsgs: state => {
      state.entitiesMsgs = initialReportState.entitiesMsgs;
    },

    fileCreateFetched: state => {
      state.reportFileCreating = false;
      state.error = null;
    },
  },
});
